import React from "react";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import LoadingButton from "./LoadingButton";

function SearchRecipes({ submitHandler, placeholder, inputType, buttonName }) {
  const inputRef = useRef("");
  const [searchLoading, setSearchLoading] = useState(false);
  const inputSubmitHandler = async (e) => {
    e.preventDefault();
    setSearchLoading(true);
    await submitHandler(inputRef.current.value);
    // inputRef.current.value = ""
    setSearchLoading(false);
  };
  return (
    <StyledSearch onSubmit={inputSubmitHandler}>
      <input
        required
        type={inputType || "text"}
        ref={inputRef}
        placeholder={placeholder || "Search Recipes"}
      />
      <LoadingButton
        loading={searchLoading}
        type="submit"
        title={buttonName || "Search"}
      />
    </StyledSearch>
  );
}

const StyledSearch = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  .MuiInputBase-root {
    /* border-radius: 50px; */
  }
  input {
    min-width: 380px;
    border-radius: 50px;
    font-size: 16px;
  }
  label {
    font-size: 16px !important;
  }
  .MuiAutocomplete-endAdornment {
    button {
      min-width: 20px;
    }
    svg {
      width: 1.6em;
      height: 1.6em;
    }
  }
  @media screen and (max-width: 600px) {
    input {
      min-width: 280px;
    }
  }
`;

export default SearchRecipes;
