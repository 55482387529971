import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../authentication/firebase";
import RecipesLoading from "../components/recipes/RecipesLoading";
import GptSearch from "../components/search/GptSearch";
import { useDispatch, useSelector } from "react-redux";
import { infoAlertActions } from "../store/infoAlertSlice";
import LoadingCircle from "../styles/LoadingCircle";
import AiRecipeGenerating from "../components/search/AiRecipeGenerating";
// import BackdropWrapper from "../components/modals/BackdropWrapper";
// import ConvertToRecipes from "../components/modals/ConvertToRecipes";

export default function AiRecipes() {
  const [gptRecipes, setGptRecipes] = useState({
    data: [],
    loading: false,
    hasMore: true,
  });
  const [newRecipe, setNewRecipe] = useState({
    data: [],
    loading: false,
    errorMsg: "",
  });
  const [userRecipes, setUserRecipes] = useState({ data: [], loading: false });
  const [newRecipeTitles, setNewRecipeTitles] = useState({});

  const uid = useSelector((state) => state.user.uid);

  const dispatch = useDispatch();

  const [lastDoc, setLastDoc] = useState(null);

  const observer = useRef();
  const lastRecipeElementRef = useCallback(
    (node) => {
      if (gptRecipes.loading || !gptRecipes.hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          getGptRecipes();
        }
      });
      if (node) observer.current.observe(node);
    },
    [gptRecipes.loading, gptRecipes.hasMore]
  );

  async function getUserRecipes() {
    setUserRecipes({ data: [], loading: true });
    try {
      const q = query(
        collection(db, "gptRecipes"),
        where("uid", "==", uid || false),
        orderBy("generationDate", "desc"),
        limit(50)
      );
      const docsRef = await getDocs(q);
      let fetchedRecipes = [];
      docsRef.forEach((doc) => fetchedRecipes.push(doc.data()));
      setUserRecipes({ data: fetchedRecipes, loading: false });
    } catch (e) {
      setUserRecipes({ data: [], loading: false });
      console.log(e);
    }
  }

  async function getGptRecipes() {
    if (gptRecipes.loading) return; // Prevent multiple simultaneous fetches
    setGptRecipes((prevRecipes) => ({ ...prevRecipes, loading: true }));
    try {
      let q;
      if (lastDoc) {
        q = query(
          collection(db, "gptRecipes"),
          orderBy("generationDate", "desc"),
          startAfter(lastDoc),
          limit(15)
        );
      } else {
        q = query(
          collection(db, "gptRecipes"),
          orderBy("generationDate", "desc"),
          limit(15)
        );
      }

      const docsRef = await getDocs(q);
      let fetchedRecipes = [];
      docsRef.forEach((doc) => {
        fetchedRecipes.push(doc.data());
      });
      setLastDoc(docsRef.docs[docsRef.docs.length - 1]);
      setGptRecipes((prevRecipes) => ({
        data: [...prevRecipes.data, ...fetchedRecipes],
        loading: false,
        hasMore: true,
      }));

      // Check if there are no more documents
      if (fetchedRecipes.length < 15) {
        console.log("in", fetchedRecipes);
        setGptRecipes((prevRecipes) => ({ ...prevRecipes, hasMore: false }));
      }
    } catch (e) {
      setGptRecipes({ data: [], loading: false, hasMore: false });
      console.log(e);
    }
  }
  useEffect(() => {
    if (uid) {
      getUserRecipes();
    }
    getGptRecipes();
    // dispatch(
    //   infoAlertActions.setAlert({
    //     title: "Generate custom recipes with our AI.",
    //     messageType: "info",
    //   })
    // );
  }, [uid]);

  return (
    <>
      <Nav />
      <StyledAI>
        <div className="heading">
          <h3>AI Recipes</h3>
        </div>
        <GptSearch
          gptRecipes={gptRecipes}
          newRecipe={newRecipe}
          setNewRecipe={setNewRecipe}
          setUserRecipes={setUserRecipes}
          setNewRecipeTitles={setNewRecipeTitles}
        />
        {newRecipe.loading && Object.keys(newRecipeTitles).length > 0 ? (
          <AiRecipeGenerating recipes={newRecipeTitles} />
        ) : (
          <RecipesLoading className={"check"} data={newRecipe} />
        )}
        <RecipesLoading
          notFoundMsg="No AI recipe generated"
          data={userRecipes}
          title="Generated by you"
        />
        <RecipesLoading
          lastElementRef={lastRecipeElementRef}
          data={gptRecipes}
          title="Generated Recipes"
        />
        {gptRecipes.loading && <LoadingCircle />}
      </StyledAI>
      <Footer />
    </>
  );
}

const StyledAI = styled.section`
  width: var(--tablet-width);
  margin: var(--section-margin) auto 0 auto;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--heading-margin-sm);
  }
  @media (max-width: 600px) {
    text-align: center;
    .heading {
      justify-content: center;
    }
  }
`;
