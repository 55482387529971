import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

function ViewAll({navigate}) {
  return (
      <StyledLink className="view-all" to={navigate} >View all <ArrowForwardIcon fontSize="small" /></StyledLink>
  );
}

const StyledLink = styled(Link)`
  color: var(--text-light-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ViewAll;
