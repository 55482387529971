import React from "react";
import styled from "styled-components";
import SkeletonLoader from "../Skeleton";
import RecipeCard from "./RecipeCard";

export default function RecipesLoading({
  title,
  data,
  addBtn,
  lastElementRef,
  className,
  notFoundMsg,
  skeletonValue,
}) {
  let recipesData = JSON.parse(JSON.stringify(data.data))
  return (
    <StyledLoading className={className}>
      <h4>{title}</h4>
      {recipesData?.length > 0 ? (
        <StyledRecipes className="card-data">
          {recipesData.map((recipe, index) =>
            recipesData.length === index + 1 ? (
              <div ref={lastElementRef} key={recipe.id}>
                <RecipeCard key={index} data={recipe} addBtn={addBtn} />
              </div>
            ) : (
              <RecipeCard key={index} data={recipe} addBtn={addBtn} />
            )
          )}
        </StyledRecipes>
      ) : data.loading === true ? (
        <SkeletonLoader skeletonValue={skeletonValue} />
      ) : (
        <div className="recipe-not-found">
          {data?.errorMsg === undefined ? (
            <h5>No recipe found</h5>
          ) : notFoundMsg ? (
            <h5>{notFoundMsg}</h5>
          ) : (
            <h5>{data?.errorMsg}</h5>
          )}
        </div>
      )}
    </StyledLoading>
  );
}

const StyledLoading = styled.div`
  .recipe-not-found {
    h5 {
      text-align: center;
      margin: 40px auto;
    }
  }
  @media (max-width: 600px) {
    text-align: center;
  }
`;

const StyledRecipes = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 20px;
  column-gap: 30px;
  margin: 20px 0;
  padding: 20px 10px;
  border-radius: 24px;
`;
