import styled from "styled-components";
import bg from "../assets/landing-page/1home-image.png";
import { useNavigate } from "react-router";
import leaf from "../assets/dashboard/leaf.png";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";

export default function LandingHome() {
  const navigate = useNavigate();
  const mediaQuery = useMediaQuery("(min-width: 1000px)");
  const mediaQueryMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      {mediaQuery && (
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={{ ease: "easeIn", delay: 0.1, duration: 0.7 }}
          style={{
            position: "absolute",
            top: "-2.4%",
            right: "-32%",
            width: "80%",
            zIndex: 0,
            transform: "rotate(-140deg)",
            overflow: "hidden",
          }}
          src={bg}
          alt="break bread"
          className="bg-img"
        />
      )}
      <StyledHome>
        <motion.div
          initial={{ y: "20%" }}
          animate={{ y: 0 }}
          transition={{ ease: "easeOut", duration: 1 }}
          className="round-shape"
        ></motion.div>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 100 }}
          transition={{ ease: "easeOut", delay: 0.3, duration: 0.4 }}
          className="absolute leaf1"
          src={leaf}
          alt="break bread"
        />
        <div className="home">
          <div className="text">
            <motion.h2
              initial={{ opacity: 0, y: 16 }}
              animate={{ opacity: 100, y: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
            >
              <span className="blue">Curated, </span>
              <span className="yellow">inspired, </span>
              <span className="green">mindful </span>
              {/* <span className="red">mindful, </span> */}
              cookbook!
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 16 }}
              animate={{ opacity: 100, y: 0 }}
              transition={{ ease: "easeOut", duration: 0.4 }}
            >
              Plan your daily meals from thousands of recipes and get the
              ingredients delivered to your doorsteps.
            </motion.p>
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 100 }}
              transition={{ ease: "easeOut", delay: 0.3, duration: 0.3 }}
              onClick={(e) =>
                // navigate("/login", { state: { from: "/dashboard/explore" } })
                navigate("/dashboard/explore")
              }
              className="explore-now-gtag"
            >
              Explore Now
            </motion.button>
          </div>
          <div className="video">
            {/* <iframe
              src={
                mediaQueryMobile
                  ? "https://www.youtube.com/watch?v=nlGN7yA2w5Q"
                  : "https://www.youtube.com/watch?v=WoZaLGtL0UA"
              }
              allow="autoplay"
              controls
              title={"Intro Video"}
            /> */}
            <iframe
              className="intro-video-gtag"
              src={
                mediaQueryMobile
                  ? "https://www.youtube.com/embed/nlGN7yA2w5Q?si=Tskd9wkesOV5m1H-"
                  : "https://www.youtube.com/embed/WoZaLGtL0UA?si=pZ3onQ0HVvFAjhDN"
              }
              title="Intro Video"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </StyledHome>
    </>
  );
}

const StyledHome = styled.section`
  position: relative;
  .round-shape {
    width: 105%;
    height: 600px;
    position: absolute;
    bottom: 0;
    left: -2.5%;
    background-color: #e2ffd7;
    border-radius: 50%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: -10;
  }
  .absolute {
    width: 80px;
    position: absolute;
  }
  .leaf1 {
    bottom: 20%;
  }
  .leaf2 {
    bottom: 0%;
    right: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .home {
    /* display: flex; */
    align-items: center;
    min-height: 80vh;
    margin: auto 8%;
    position: relative;
    button {
      background-color: var(--accent-color);
      &:hover {
        background-color: var(--accent-dark-color);
      }
    }
    .text {
      padding-top: 7%;
      h2 {
        line-height: 1.2;
        max-width: 18ch;
        /* color: var(--primary-color); */
        color: var(--text-color);
        span {
          font-size: inherit;
          font-weight: inherit;
          color: var(--primary-color);
        }
        .green {
          color: var(--green-color);
        }
        .yellow {
          color: var(--orange-color);
        }
      }
      p {
        margin: 10px 0;
        max-width: 45ch;
      }
    }
    .video {
      display: flex;
      align-items: center;
      justify-content: center;
      iframe {
        width: 60%;
        aspect-ratio: 16/9;
        border-radius: 24px;
        box-shadow: 2px 2px 12px var(--shadow);
      }
    }
  }
  @media (max-width: 600px) {
    .round-shape {
      display: none;
    }
    .leaf1,
    .leaf2 {
      width: 40px;
    }
    .home {
      justify-content: center;
      min-height: 60vh;
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        h2 {
          text-align: center;
          font-size: 1.6rem;
        }
        p {
          text-align: center;
        }
      }
      .video {
        iframe {
          width: 80%;
          aspect-ratio: 9/16;
        }
        margin-bottom: var(--section-margin);
      }
    }
  }
  @media (min-width: 600px) and (max-width: 900px) {
    .home {
      .text {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2 {
          text-align: center;
        }
      }
      .video iframe {
        width: 100%;
      }
    }
  }
`;
