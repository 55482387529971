import { deleteDoc, doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { db } from "../../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import { userRecipesActions } from "../../store/userRecipesSlice";
import { alertActions } from "../../store/alertSlice";
import { CircularProgress, Fade, Tooltip } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { FavoriteRounded } from "@mui/icons-material";
import { modalActions } from "../../store/modalSlice";
import styled from "@emotion/styled";

export default function FavoriteRecipeHandler({ data }) {
  const [favorite, setFavorite] = useState({
    checked: data.recipeType === "favorite",
    loading: false,
  });

  const uid = useSelector((state) => state.user.uid);
  const dispatch = useDispatch();

  async function addFavoriteHandler() {
    if (!uid) {
      localStorage.setItem("routePath", `/recipe/${data.id}`);
      dispatch(modalActions.setLoginModalActive(true));
      return;
    }
    setFavorite((prev) => {
      return { ...prev, loading: true };
    });
    data = { ...data, recipeType: "favorite" };
    try {
      await setDoc(doc(db, "favoriteRecipes", data.id), {
        ...data,
        uid,
      });
      setFavorite({ checked: true, loading: false });
      dispatch(
        userRecipesActions.appendUserRecipes({ data, recipeType: "favorite" })
      );
      dispatch(
        alertActions.setAlert({
          title: "Recipe added to favorites",
          messageType: "success",
        })
      );
      window.dataLayer.push({
        event: "recipe-added-to-favorites",
        recipeId: data.id,
      });
    } catch (error) {
      console.log("error", error);
      // dispatch(
      //   alertActions.setAlert({
      //     title: "Failed to add to favorites",
      //     messageType: "error",
      //   })
      // );
    }
    setFavorite((prev) => {
      return { ...prev, loading: false };
    });
  }

  const removeFavoriteHandler = async () => {
    console.log("docId", data.id);
    setFavorite((prev) => {
      return { ...prev, loading: true };
    });
    try {
      await deleteDoc(doc(db, "favoriteRecipes", data.id.toString()));
      setFavorite({ checked: false, loading: false });
      dispatch(
        userRecipesActions.deleteUserRecipe({ data, recipeType: "favorite" })
      );
      dispatch(
        alertActions.setAlert({
          title: "Recipe removed from favorites",
          messageType: "success",
        })
      );
      window.dataLayer({
        event: "recipe-removed-from-favorites",
        recipeId: data.id,
      });
    } catch (error) {
      console.log("error", error);
      // dispatch(
      //   alertActions.setAlert({
      //     title: "Failed to unfavorite recipe",
      //     messageType: "error",
      //   })
      // );
    }
    setFavorite((prev) => {
      return { ...prev, loading: false };
    });
  };
  return (
    data.nutrition && (
      <StyledFavoriteButton>
        {favorite.loading ? (
          <button className="icon favorite-btn">
            <CircularProgress
              style={{
                color: "var(--primary-dark-color)",
                width: "17px",
                height: "17px",
              }}
            />
          </button>
        ) : favorite.checked ? (
          <Tooltip title="Unsave recipe">
            <button
              onClick={removeFavoriteHandler}
              className="icon favorite-btn"
            >
              <Fade in={favorite.checked}>
                <FavoriteIcon
                  fontSize="small"
                  htmlColor="var(--accent-color)"
                />
              </Fade>
            </button>
          </Tooltip>
        ) : (
          <Tooltip title="Save recipe">
            <button onClick={addFavoriteHandler} className="icon favorite-btn">
              <Fade in={!favorite.checked}>
                <FavoriteRounded
                  fontSize="small"
                  htmlColor="var(--gray-light-color)"
                />
              </Fade>
            </button>
          </Tooltip>
        )}
      </StyledFavoriteButton>
    )
  );
}

const StyledFavoriteButton = styled("div")`
  .favorite-btn {
    background-color: var(--background-color);
    border-radius: 6px;
    height: 28px;
    position: absolute;
    top: 5%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
