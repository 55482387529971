import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export default function Notification({ backdropHandler, heading, msg }) {
  return (
    <StyledNotification>
      <div className="heading">
        <h4>{heading}</h4>
        <button className="icon" onClick={(e) => backdropHandler(false)}>
          {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
        </button>
      </div>

      <div className="content">
        <span>{msg}</span>
        <button onClick={backdropHandler}>Close</button>
      </div>
    </StyledNotification>
  );
}

const StyledNotification = styled.section`
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    span {
      max-width: 35ch;
      text-align: center;
    }
  }
`;
