export const recipeShortFormat = `{
    title: String,
    preparationMinutes: Number,
    healthScore: Int or float ranges from 1 to 100,
    readyInMinutes: Number,
    servings: Number,
    image: "",
    summary: "generate description of the recipe, around 500 words. Don't leave this field empty. Be creative",
    healthBenefitsShort: ["concise health benefits list of ingredients used"],
    alternativeRecipes: ["add list of alternatives recipes"],
    dishTypes: [
      "array of values that could be breakfast, lunch, dinner, snack, fast food, dessert, smothie, cake, juice, healthy, salad or more depending upon the dish type the recipe falls in",
    ],
    dietCategory: [
      "include diets the recipe follows"
    ],
    steps: [include steps here, array of strings not objects],
  }`;

export const recipeLongFormat = `{
    title: String,
    sustainable: Boolean,
    cookingMinutes: Number,
    healthBenefitsLong: [detailed health benefits list of ingredients used],
    healthyAlternativeRecipes: [
      add list of alternatives recipes that are healthier
    ],
    cuisines: [array of the cuisines a recipe fall into],
    dietaryRestrictions: [include dietary restrictions the recipe has],
    origin: {region: "region where the recipe is based", country: "country where the recipe is from"}
    specialOccasions: ["Array containing a list of special occasions that this recipe is made for. Only provide occasion names not explanation"] 
    extendedIngredients: [
      {
        aisle:
          String(aisle to which the ingredient belongs to(use standard US aisle names for a grocery store)),
        image: "",
        consistency: String(solid, liquid or anything else),
        nameClean:
          String(the adjective with the ingredient name should be after the name),
        original: String(include the description of ingredient here),
        amount: Number(int or float)(ingredient's amount),
        unit: String(ingredient's amount unit),
        measures: {
          us: {
            amount: Number(int or float)(ingredient's amount),
            unitShort: String(short unit),
            unitLong: String(long unit),
          },
          metric: {
            amount: Number(int or float)(ingredient's amount),
            unitShort: String(short unit),
            unitLong: String(long unit),
          },
        },
      },
    ],
    carbonFootprint: String(describe the carbon footprint of the recipes here in few lines. Compare the carbon footprint of the recipe to a real life example like cars, planes, electricity usage etc),
    foodWaste: Object(this field is for food waste estimation of the recipe. This field is an object, the keys of the object contains the name of the ingredient whose little portion might go to waste while preparing the recipe and the values of the object contains the waste amount. Follow this format: {"ingredient name": "waste amount (note: amount should be in units like '50 grams' or '1 tablespoon', no explanation)}),
    nutrition: {
      nutrients: [
        {
          name: "Fat",
          amount: Number(amount for 1 person serving),
          unit: "g",
          percentOfDailyNeeds: Number,
        },
        {
          name: "Carbohydrates",
          amount: Number(amount for 1 person serving),
          unit: "g",
          percentOfDailyNeeds: Number,
        },
        {
          name: "Sugar",
          amount: Number(amount for 1 person serving),
          unit: "g",
          percentOfDailyNeeds: Number,
        },
        {
          name: "Protein",
          amount: Number(amount for 1 person serving),
          unit: "g",
          percentOfDailyNeeds: Number,
        },
        {
          name: "Calories",
          amount: Number(amount for 1 person serving),
          unit: "kcal",
          percentOfDailyNeeds: Number,
        },
      ],
    },
  }`;
  
export const titlesWithIngredientsFormat = `{
    [valid and actual recipe name]: [{nameClean: [valid ingredient name here(first letter capital)], amount: [amount here type=(int or float)], unit: [unit here], servings: Int}],
    ...
  }`;

export const recipeTitlesFormat = `{name1: ["leave empty string"], name2: ["leave empty string"], ...}`
