import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { useDispatch, useSelector } from "react-redux";
import { doc, writeBatch, deleteField } from "firebase/firestore";
import { db } from "../authentication/firebase";
import SearchRecipes from "../components/SearchRecipes";
import LoadingButton from "../components/LoadingButton";
import { useNavigate } from "react-router";
import { alertActions } from "../store/alertSlice";
import { DataGrid } from "@mui/x-data-grid";
import firebaseApi from "../utils/firebaseApi";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { instacartApi } from "../utils/apis";
import InstacartLogo from "../assets/Instacart_Carrot.png";

let toArchive = {};
let cartItems = [];
let selectedItems = [];
let rowsSelected = [];
let selectedRowsSet;
function ShoppingCart() {
  const [cartLoading, setCartLoading] = useState(true);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid, fullname, email } = useSelector((state) => state.user);
  const [queryString, setQueryString] = useState(``);
  const mediaQuery = useMediaQuery("(max-width: 600px)");
  const [instacartLink, setInstacartLink] = useState({
    link: "",
    loading: false,
    error: "",
  });

  const columns = mediaQuery
    ? [
        {
          field: "image",
          headerName: "",
          width: 80,
          renderCell: (params) => <img alt="" src={params.value} />,
        },
        {
          field: "nameClean",
          headerName: "Name",
          // width: 200,
          renderCell: (params) => (
            <h5>
              {params.value && params.value.length > 1
                ? params.value.charAt(0).toUpperCase() + params.value.slice(1)
                : ""}
            </h5>
          ),
        },
        { field: "amount", headerName: "Amount" },
      ]
    : [
        {
          field: "image",
          headerName: "",
          // width: 120,
          renderCell: (params) => <img alt="" src={params.value} />,
        },
        {
          field: "nameClean",
          headerName: "Name",
          width: 200,
          renderCell: (params) => (
            <h5>
              {params.value && params.value.length > 1
                ? params.value.charAt(0).toUpperCase() + params.value.slice(1)
                : ""}
            </h5>
          ),
        },
        { field: "amount", headerName: "Amount" },
        { field: "date", headerName: "Date", width: 150 },
      ];

  const fetchShoppingCart = useCallback(async () => {
    try {
      const url = `/getSubCollectionsData?docPath=shoppingCart/${uid}`;
      const response = await firebaseApi.get(url);
      console.log("response", response.data);
      toArchive = response.data;
      let fetchedDocs = [];
      Object.entries(toArchive).map(async ([month, value]) => {
        Object.entries(value).forEach(([date, data]) => {
          Object.entries(data).forEach(([mealType, item]) => {
            Object.values(item).forEach((ingredient) =>
              fetchedDocs.push({
                ...ingredient,
                date: `${month}-${date}`,
                mealType,
              })
            );
          });
        });
      });
      let ingredients = [];
      fetchedDocs.forEach(
        (
          { nameClean, amount, date, image, measures, unit, mealType },
          index
        ) => {
          if (image === "")
            image = nameClean?.toLowerCase()?.split(" ").join("-") + ".jpg";
          if (ingredients.length === 0)
            ingredients.push({
              id: index,
              nameClean,
              amount: `${amount} ${measures ? measures.us.unitShort : unit}`,
              date: [date],
              image: `https://spoonacular.com/cdn/ingredients_100x100/${image}`,
              mealType: [{ [date]: mealType }],
              measures,
            });
          else {
            const foundIndex = ingredients.findIndex(
              (data) => data.nameClean === nameClean
            );

            if (foundIndex === -1)
              ingredients.push({
                id: index,
                nameClean,
                amount: `${amount} ${measures ? measures.us.unitShort : unit}`,
                date: [date],
                image: `https://spoonacular.com/cdn/ingredients_100x100/${image}`,
                mealType: [{ [date]: mealType }],
                measures,
              });
            else {
              const prevAmount = parseInt(
                ingredients[foundIndex].amount.split(" ")[0]
              );
              const unit = ingredients[foundIndex].amount.split(" ")[1];
              ingredients[foundIndex].amount = prevAmount + amount + " " + unit;
              if (!new Set(ingredients[foundIndex].date).has(date))
                ingredients[foundIndex].date.push(date);
              ingredients[foundIndex].mealType.push({ [date]: mealType });
            }
          }
        }
      );
      cartItems = ingredients;
      // cartItems = ingredients.map((ingredient) => {
      //   let nameArray = ingredient.nameClean.split(" ");
      //   if (nameArray.length === 1) return ingredient;
      //   else {
      //     const firstElement = nameArray.shift()
      //     nameArray.push(firstElement)
      //     nameArray = nameArray.join(" ")
      //     return {
      //       ...ingredient,
      //       nameClean: nameArray,
      //     };
      //   }
      // });
      setCartLoading(false);
    } catch (error) {
      setCartLoading(false);
      console.log("error", error);
    }
  }, []);
  useEffect(() => {
    fetchShoppingCart();
  }, []);

  const emailHandler = async (inputValue) => {
    try {
      const url = `/sendCartToEmail?uid=${uid}&email=${inputValue}`;
      const res = await firebaseApi.get(url);
      console.log("res", res.data);
      dispatch(
        alertActions.setAlert({
          title: "Email sent successfully",
          messageType: "success",
        })
      );
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: "Failed to send email",
          messageType: "error",
        })
      );
      console.log("error", e);
    }
  };
  const archiveClickHandler = async () => {
    setArchiveLoading(true);
    // emailHandler()
    try {
      const batch = writeBatch(db);
      // // set collection
      Object.entries(toArchive).forEach(([month, item]) => {
        console.log("month", month);
        Object.entries(item).forEach(async ([date, value]) => {
          const docRef = doc(db, `cartArchiveList/${uid}/${month}`, date);
          batch.set(docRef, value, { merge: true });
        });
      });
      await batch.commit();
      const url = `/deleteCollection?docPath=shoppingCart/${uid}`;
      await firebaseApi.get(url);
      cartItems = [];
    } catch (e) {
      console.log("error", e);
    }
    setArchiveLoading(false);
  };

  const stateChangeHandler = (rowSelection) => {
    rowsSelected = rowSelection;
    if (cartItems.length > 0) {
      // const
      selectedRowsSet = new Set(rowSelection);
      // setQueryString(
      // (current) =>
      // `[${
      console.log("rows", rowsSelected);
      selectedItems = cartItems.filter((item, index) =>
        selectedRowsSet.has(item.id)
      );
      console.log("selected", selectedItems);
      // .map(({ nameClean }) => `"${nameClean}":1`)
      // console.log("selected", selectedItems)
      // .map(
      //   ({ nameClean, amount }) =>
      //     `{"search_term": "${nameClean}", "unit_size": "${
      //       amount.split(" ")[0]
      //     }", "unit_measurement": "${amount.split(" ")[1]}"}`
      // )
      // .join(",");
      // }         ]`
      // );
      // setQueryString(`"cheese":1`)
    }
  };

  const addClickHandler = () => {
    let selected = [];
    let unselected = [];
    cartItems.forEach((item) =>
      selectedRowsSet.has(item.id) ? selected.push(item) : unselected.push(item)
    );
    cartItems = selected.concat(unselected);
    setQueryString(selectedItems);
  };

  async function fetchInstacartShoppingLink() {
    setInstacartLink({ link: "", loading: true, error: "" });
    if (selectedItems.length === 0) {
      dispatch(
        alertActions.setAlert({
          title: "No cart item selected",
          messageType: "error",
        })
      );
      setInstacartLink({
        link: "",
        loading: false,
        error: "Please select at least one cart item.",
      });
      return;
    }
    const shoppingItems = {
      title: "BreakBread Shopping List",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/break-bread-prod.appspot.com/o/web-app-assets%2FBB%20logo%20name-01.png?alt=media&token=54d9f1f2-4a18-473c-beb2-fb35dd3053bb",
      link_type: "shopping_list",
      line_items: selectedItems?.map((ingredient) => ({
        name: ingredient.nameClean,
        display_text:
          ingredient.nameClean?.charAt(0).toUpperCase() +
          ingredient.nameClean?.slice(1),
        quantity: ingredient?.measures
          ? ingredient?.measures.metric.amount
          : ingredient.amount,
        unit: ingredient?.measures
          ? ingredient?.measures?.metric.unitLong
          : ingredient.unit,
        line_item_measurements: [
          {
            quantity: ingredient?.measures
              ? ingredient?.measures.metric.amount
              : ingredient.amount,
            unit: ingredient?.measures
              ? ingredient?.measures?.metric.unitLong
              : ingredient.unit,
          },
        ],
      })),
      landing_page_configuration: {
        partner_linkback_url: "string",
        enable_pantry_items: true,
      },
    };
    console.log("recipe info", shoppingItems);
    try {
      const res = await instacartApi.post("/products_link", shoppingItems);
      // window.location.target = "_blank";
      // window.location.href = res.data.products_link_url;
      window.open(res.data.products_link_url, "_blank");
      setInstacartLink({
        link: res.data.products_link_url,
        loading: false,
        error: "",
      });
    } catch (e) {
      console.log("error while fetching instacart recipe link", e);
      setInstacartLink((current) => ({
        ...current,
        loading: false,
        error: "Shopping cart not working",
      }));
    }
  }

  // useEffect(() => {
  //   dispatch(
  //     infoAlertActions.setAlert({
  //       title: "Select cart items and click 'Confirm Cart' button.",
  //       messageType: "info",
  //     })
  //   );
  // }, []);

  return (
    <>
      <Nav />
      <StyledCart>
        <div className="title">
          <h3>Shopping Cart</h3>
          <div className="buttons">
            <button className="cart-recipes-btn instacart-btn" onClick={() => navigate("/cart-recipes")}>
              Cart Recipes
            </button>
            <LoadingButton
              onClick={archiveClickHandler}
              className="secondary-btn"
              title="Archive Cart"
              loading={archiveLoading}
            />
            <button className="secondary-btn" onClick={() => navigate("archive-list")}>
              See Archive List
            </button>
          </div>
        </div>
        <div className="email">
          <h5>Send shopping cart items to an email.</h5>
          <SearchRecipes
            submitHandler={emailHandler}
            type="email"
            placeholder={"Email"}
            buttonName="Send"
          />
        </div>
        <div className="shopping-items">
          <div className="items">
            <DataGrid
              className="grid"
              checkboxSelection
              // rowSelectionModel={[0, 2]}
              // onCellClick={(row) => onRowClick(row)}
              getRowHeight={({ id, densityFactor }) => {
                return 110 * densityFactor;
              }}
              rows={cartItems}
              columns={columns}
              loading={cartLoading}
              onStateChange={(item) => stateChangeHandler(item.rowSelection)}
              // columnVisibilityModel={true}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: 20,
              //     },
              //   },
              // }}
              // pageSizeOptions={[10]}
              // disableRowSelectionOnClick={true}
            />
            {/* <Link
              activeClass="active"
              to="mealme-sdk"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}
            >
              <button onClick={addClickHandler}>Confirm Cart</button>
            </Link> */}
            <h5 className="error-msg">{instacartLink.error}</h5>
            <LoadingButton
              loading={instacartLink.loading}
              title={
                <>
                  <img src={InstacartLogo} alt="" /> Confirm Cart
                </>
              }
              onClick={fetchInstacartShoppingLink}
              className="instacart-btn"
            />
          </div>
        </div>
      </StyledCart>
      <Footer />
    </>
  );
}

const StyledCart = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  width: var(--tablet-width);
  margin: var(--section-margin) auto;
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 16px;
    .buttons {
      display: flex;
      gap: 20px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        /* background-color: var(--secondary-color);
        &:hover {
          background-color: var(--secondary-dark-color);
        } */
      }
    }
  }
  .shopping-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    min-height: 70vh;
    button {
      min-width: 25px;
    }
    .items {
      flex: 1 1 600px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2rem;
      min-height: 70vh;
      .grid {
        width: 100%;
      }
      .msg {
        color: var(--secondary-dark-color);
      }
      .MuiDataGrid-menuIcon {
        visibility: visible;
        width: auto;
      }
      /* margin-bottom: var(--section-margin); */
      /* display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 45%; */
      /* h5 {
        margin-top: 80px;
        text-align: center;
      } */
    }
    .mealme-sdk {
      flex: 1 1 40%;
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: 4px;
      iframe {
        height: 68vh;
      }
    }
  }
  .email {
    margin-top: 40px;
    h5 {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .instacart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #108910;
    color: white;
    img {
      width: 20px;
      height: auto;
    }
    &:hover {
      background-color: #0d760d;
    }
  }

  .error-msg {
    color: red;
  }
  @media (max-width: 600px) {
    .title {
      justify-content: center;
    }
  }
`;

export default ShoppingCart;
