import React from "react";
import styled from "styled-components";
import calendar from "../assets/landing-page/calendar.png";
import recipes from "../assets/landing-page/recipes.png";
import mobileOrder from "../assets/landing-page/mobile-order.png";
import { Element } from "react-scroll";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

export default function LandingAbout() {
  const navigate = useNavigate();

  return (
    <Element name="about">
      <StyledAbout>
        <motion.h3
          initial={{ opacity: 0, y: 16 }}
          whileInView={{ opacity: 100, y: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", delay: 0.3, duration: 0.4 }}
        >
          How it Works?
        </motion.h3>
        <motion.div
          initial={{ opacity: 0, y: 16 }}
          whileInView={{ opacity: 100, y: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", delay: 0.3, duration: 0.4 }}
          className="content"
        >
          <div className="box box1">
            <div>
              <img src={recipes} alt="break bread" />
            </div>
            <h5>
              <span>1</span> Search recipes
            </h5>
            <p>
              Search thousands of recipes based on different cuisines and diets
            </p>
          </div>
          <div className="box box2">
            <div>
              <img src={calendar} alt="break bread" />
            </div>
            <h5>
              <span>2</span> Plan your daily meals
            </h5>
            <p>Add your favorite recipes to our build-in calendar</p>
          </div>
          <div className="box box3">
            <div>
              <img src={mobileOrder} alt="break-bread" />
            </div>
            <h5>
              <span>3</span> Buy recipes ingredients
            </h5>
            <p>
              Get the ingredients of your planned recipes delivered to your
              doorsteps
            </p>
          </div>
        </motion.div>
        <motion.button
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 100 }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", duration: 0.2 }}
          className="join-now-gtag"
          onClick={(e) => navigate("/dashboard")}
        >
          Join Now
        </motion.button>
      </StyledAbout>
    </Element>
  );
}

const StyledAbout = styled.section`
  background: linear-gradient(#e2ffd7 5%, white);
  text-align: center;
  padding: var(--section-margin) 0 5%;
  /* padding-top: var(--section-margin);
  padding-bottom: var(--section-margin); */
  h3 {
    /* color: var(--accent-color); */
    color: var(--orange-color);
    margin-bottom: var(--heading-margin);
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1.4rem;
    margin-bottom: 2rem;
    .box {
      text-align: center;
      div {
        height: 200px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 20px;
        img {
          max-width: 260px;
        }
      }
      h5 {
        margin-bottom: 12px;
        span {
          font-weight: bold;
          background-color: var(--green-color);
          padding: 5px 12px;
          border-radius: 50px;
          color: white;
        }
      }
      p {
        max-width: 34ch;
      }
    }
    .box:first-child {
      h5 span {
        padding: 5px 13.5px;
      }
    }
  }
`;
