import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, Firestore, getFirestore } from "firebase/firestore";
import "firebase/compat/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
// Test

let firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const dbStorage = getStorage(app);
// connectFirestoreEmulator(db, "http://127.0.0.1", 8080);
// connectAuthEmulator(auth, "http://127.0.0.1:9099")
// connectStorageEmulator(dbStorage, "http://127.0.0.1:9191")
export default firebaseConfig;
