import styled from "@emotion/styled";
import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import ConvertToRecipes from "../components/modals/ConvertToRecipes";

export default function FilesToRecipes() {
  return (
    <>
      <Nav />
      <StyledFilesToRecipes>
        <h3 className="heading">Capture Recipes</h3>
        <div className="box">
          <h2>Convert files to recipes</h2>
          <p className="description">
            Easily convert your images or pdfs into recipes and add to your
            calendar or share with your loved ones.
          </p>
          <ConvertToRecipes hideCloseIcon={true} />
        </div>
      </StyledFilesToRecipes>
      <Footer />
    </>
  );
}

const StyledFilesToRecipes = styled.section`
  width: var(--tablet-width);
  margin: var(--section-margin) auto;
  .heading {
    margin-bottom: var(--heading-margin-sm);
  }
  .box {
    margin: auto;
    text-align: center;
    h2 {
      font-size: 2.2rem;
      color: var(--primary-color);
    }
    .description {
      color: var(--secondary-dark-color);
      max-width: 40ch;
      margin: auto;
      margin-bottom: var(--heading-margin);
    }
  }
  @media (max-width: 600px) {
    h3 {
      text-align: center;
    }
  }
`;
