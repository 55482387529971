import { useEffect, useState, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { useNavigate } from "react-router";
import { getDoc, getDocs, doc, collection } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { mealPlanningActions } from "../../store/mealPlanningSlice";

export default function Calendar() {
  document.querySelector(".fc-day");
  const navigate = useNavigate();
  const uid = useSelector((state) => state.user.uid);
  const monthMeals = useSelector((state) => state.mealPlanning.monthMeals);
  const [yearMonth, setYearMonth] = useState(
    moment(new Date()).format("YYYY-MM")
  );
  const dispatch = useDispatch();

  const fetchDates = async () => {
    try {
      const collectionRef = collection(db, `dailyMeals/${uid}/${yearMonth}`);
      const monthData = await getDocs(collectionRef);
      let fetchedDocs = {};
      monthData.forEach((data) => {
        fetchedDocs[`${yearMonth}-${data.id}`] = data.data();
      });
      dispatch(mealPlanningActions.monthMeals(fetchedDocs));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDateClick = (arg) => {
    window.dataLayer.push({
      event: "calendar-date-selected",
      selectedDate: arg.dateStr,
    });
    navigate(`/dashboard/meal-planning/${arg.dateStr}`);
  };

  useEffect(() => {
    uid && fetchDates();
  }, [uid, yearMonth]);

  useEffect(() => {}, [monthMeals]);

  return (
    <StyledCalendar className="calendar">
      <FullCalendar
        className="calendar-gtag"
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        datesSet={(dateInfo) => {
          setYearMonth(
            moment(dateInfo.start).add(10, "days").format("YYYY-MM")
          );
        }}
        // dateMouseEnter={(e) => console.log("event mouse enter")}
        // dateMouseLeave={(e) => console.log("event mouse leave")}
        weekends={true}
        // eventClick={(e) => console.log("event clicked")}
        dateClick={(e) => handleDateClick(e)}
        events={Object.entries(monthMeals).map(([date, data]) => ({
          title: "planned",
          date: Object.keys(data).length > 0 && date,
        }))}
      />
    </StyledCalendar>
  );
}

const StyledCalendar = styled.div`
  /* .fc{
  width: 100%;
} */
  .fc {
    .fc-toolbar {
      h2 {
        font-size: 1.2rem;
        font-weight: 600;
      }
      button {
        line-height: 1;
        padding: 4px;
        span {
          font-size: 1rem;
        }
      }
    }
    .fc-h-event {
      background-color: var(--green-color);
    }
    .fc-toolbar-title {
      font-size: 1.4rem;
    }
  }
`;
