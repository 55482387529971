import styled from "styled-components";
import React, { useRef, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import signupBg from "../assets/Login-Signup/signup-bg.webp";
import plate from "../assets/Login-Signup/plate.webp";
import leaf1 from "../assets/Login-Signup/leaf-tomato1.webp";
import leaf5 from "../assets/Login-Signup/leaf-tomato5.webp";
import leaf3 from "../assets/Login-Signup/leaf-tomato3.webp";
import leaf4 from "../assets/Login-Signup/leaf-tomato4.webp";
import { auth } from "../authentication/firebase";
import LoadingButton from "../components/LoadingButton";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithRedirect,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { alertActions } from "../store/alertSlice";
import BackdropWrapper from "../components/modals/BackdropWrapper";
import Notification from "../components/modals/Notification";
import { useMediaQuery } from "@mui/material";
import SignInWithoutEmail from "../authentication/SignInWithoutEmail";

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://break-bread-web-app.web.app/login",
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: "https://break-bread-web-app.web.app.ios",
  // },
  // android: {
  //   packageName: "https://break-bread-web-app.web.app.android",
  //   installApp: true,
  //   minimumVersion: "10",
  // },
  // dynamicLinkDomain: "example.page.link",
};

function Signup() {
  const fullnameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);

  const mediaQuery = useMediaQuery("(min-width: 1050px)");
  const dispatch = useDispatch();


  const navigate = useNavigate();


  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      );
      // localStorage.setItem("displayName", fullnameRef.current.value);
      const verfiyEmail = await sendEmailVerification(response.user);
      console.log("verfiy email", verfiyEmail);
      // await sendSignInLinkToEmail(auth, emailRef.current.value, actionCodeSettings)
      setBackdrop(true);
      window.dataLayer.push({
        event: "signup-via-form",
      });
      // await updateProfile(response.user, {
      //   displayName: fullnameRef.current.value,
      //   photoURL: fullnameRef.current.value.charAt(0).toUpperCase(),
      // });
      // await setDoc(doc(db, "users", response.user.uid), {
      //   fullname: response.user.displayName,
      //   email: response.user.email,
      //   uid: response.user.uid,
      //   photoURL: response.user.displayName.charAt(0).toUpperCase(),
      // });
      // navigate("/profile-setup/preferences");
    } catch (e) {
      dispatch(
        alertActions.setAlert({
          title: JSON.parse(JSON.stringify(e)).code,
          messageType: "error",
        })
      );
      console.log("Failed to create an account", e);
    }
    setLoading(false);
  };

  const backdropHandler = () => {
    setBackdrop((current) => {
      if (current === true) navigate("/login");
      return !current;
    });
  };


  return (
    <StyledSignup>
      <BackdropWrapper
      handleBackdrop={backdropHandler}
        element={
          <Notification
            heading="Email Verification"
            msg="Email verification link has been sent to your email, please verify and login. If you don't receive the email in inbox, please check spam."
            backdropHandler={backdropHandler}
          />
        }
        smallSize={true}
        open={backdrop}
      />
      {mediaQuery && (
        <>
          <img src={plate} alt="plate" className="plate-img" />
        </>
      )}
      <div className="image-div">
        <img src={signupBg} alt="break bread" />
      </div>
      <div className="text">
        <img className="img1" src={leaf1} alt="leaf" />
        <img className="img2" src={leaf5} alt="leaf" />
        <img className="img3" src={leaf3} alt="leaf" />
        <img className="img4" src={leaf4} alt="leaf" />
        <h1>
          Fill the form to become part of <span>BreakBread</span> Team
        </h1>
        <form onSubmit={submitHandler}>
          {/* <div>
            <input
              placeholder="Name"
              name="fullname"
              type="text"
              required={true}
              ref={fullnameRef}
              pattern=".{4,}"
              title="Name must be at least 4 characters long"
            />
          </div> */}
          <div>
            <input
              placeholder="Email"
              name="email"
              type="email"
              required={true}
              ref={emailRef}
            />
          </div>
          <div>
            <input
              placeholder="Password"
              name="password"
              type="password"
              required={true}
              ref={passwordRef}
              pattern=".{8,}"
              title="Password must be at leas t 8 characters long"
            />
          </div>
          <div className="submit-btn">
            <LoadingButton loading={loading} type="submit" title="Sign Up" />
            <div>
              <span>Already have an account?</span>
              <Link to="/login"> Login</Link>
            </div>
          </div>
        </form>
        <div className="space">
          <span>Or</span>
          <div />
        </div>
        <SignInWithoutEmail />
      </div>
    </StyledSignup>
  );
  // );
}

const StyledSignup = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  span {
    color: var(--text-light-color);
  }
  h1 {
    line-height: 1.2;
    color: var(--primary-color);
    max-width: 16ch;
    text-align: left;
    span {
      font-size: inherit;
      color: var(--secondary-color);
      font-weight: inherit;
    }
  }
  .plate-img {
    max-width: 30vw;
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: 10;
  }
  .image-div {
    flex-basis: 40%;
    z-index: -1;
    left: 0;
    img {
      height: 100vh;
    }
  }
  .text {
    position: relative;
    min-height: 90vh;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    .img1,
    .img2,
    .img3,
    .img4 {
      position: absolute;
      z-index: -1;
      width: 120px;
    }
    .img1 {
      left: -7%;
      top: 1%;
    }
    .img4 {
      right: 0;
      top: -3.4%;
    }
    .img2 {
      left: 13%;
      top: 70%;
    }
    .img3 {
      right: 10%;
      bottom: -6.1%;
    }
    form {
      width: 40%;
      a {
        color: var(--secondary-color);
      }
      input {
        height: 50px;
        width: 100%;
      }
      .submit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      button {
        align-self: center;
        background-color: var(--accent-color);
        &:hover {
          background-color: var(--accent-dark-color);
        }
      }
      div {
        margin: 10px auto;
        label {
          float: left;
          margin-bottom: 4px;
        }
      }
    }
    .space {
      position: relative;
      width: 38%;
      div {
        width: 100%;
        background-color: var(--text-light-color);
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: -1;
      }
      span {
        background-color: var(--background-color);
        z-index: 10;
        padding: 0 2rem;
      }
    }
    .google-button {
      color: black;
      background-color: transparent;
      border: 1px solid var(--text-light-color);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 30px;
        height: 30px;
        margin-right: 1rem;
      }
    }
  }
  .submit-btn a{
    text-decoration: underline;
  }
  @media screen and (max-width: 500px) {
    .image-div {
      position: absolute;
    }
    span {
      color: var(--text-color);
    }
    .text .space {
      div {
        background-color: transparent;
      }
      span {
        background-color: transparent;
      }
    }
    .text {
      width: 100%;
      img {
        display: none;
      }
      h1 {
        text-align: center;
      }
      form {
        width: 80%;
      }
    }
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    .image-div {
      position: absolute;
    }
    .text {
      width: 80%;
      margin: auto;
      h1 {
        text-align: center;
      }
      img {
        display: none;
      }
      form {
        width: 50%;
      }
    }
  }
`;

export default Signup;
