import React from "react";
import LandingHome from "./LandingHome";
import LandingExplore from "./LandingExplore";
import LandingFooter from "./LandingFooter";
import Faq from "./Faq";
import LandingAbout from "./LandingAbout";
import LandingAI from "./LandingAI";
import Upcoming from "./Upcoming";
import styled from "styled-components";
import LandingNavbar from "./LandingNavbar";

export default function LandingPage() {
  return (
    <StyledLanding className="new">
      <LandingNavbar />
      <LandingHome />
      <LandingAbout />
      <LandingAI />
      <LandingExplore />
      {/* <Upcoming /> */}
      <Faq />
      <LandingFooter />
    </StyledLanding>
  );
}

const StyledLanding = styled.section`
h3,h4,h5{
  font-weight: 600;
}
h3{
  font-size: 1.8rem;
}
button{
  background-color: var(--secondary-color);
  &:hover{
    background-color: var(--secondary-dark-color);
  }
}
`