import React, { useEffect, useState } from "react";
import leaf from "../assets/dashboard/leaf.png";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import styled from "styled-components";
import {
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import firebaseApi from "../utils/firebaseApi";

const temp = [
  { name: "Protein", value: 400 },
  { name: "Sugar", value: 600 },
  { name: "Fats", value: 300 },
  { name: "Carbohydrates", value: 200 },
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        style={{ fontWeight: "bold" }}
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={fill}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        style={{ fontWeight: "bold" }}
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};
const COLORS = ["#FFBB28", "#FF8042", "#00C49F", "#0088FE"];

let filterDays = null;
let apiData = {};
export default function NutritionChart({ nutritionType }) {
  const tabs = [
    nutritionType === "planned" && { name: "Today", value: 0 },
    { name: "3 day", value: 3 },
    { name: "1 week", value: 7 },
    { name: "1 month", value: 30 },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const [data, setData] = useState({
    loading: false,
    fetchedData: [],
    calories: 0,
  });
  const uid = useSelector((state) => state.user.uid);

  // console.log("nutrition", data)
  const hitApiAndFetch = async () => {
    try {
      if(uid){
    const url = `/getSubCollectionsData?docPath=mealsNutrition/${uid}`;
      setData(prev => ({...prev, loading: true}))
      const response = await firebaseApi.get(url);
      apiData = response.data;
      console.log("respon", response, uid);
      response.data && fetchNutrition();
      }
    } catch (e) {
      setData((prev) => ({ ...prev, loading: false }));
      console.log("error", e);
    }
  };
  const fetchNutrition = async () => {
    let nutrients = [];
    let calories = 0;
    const todayDate = moment().format("YYYY-MM-DD");
    // keep this function inside the fetchNutrition function
    const setNutrition = (oneDayNutrition) => {
      Object.values(oneDayNutrition).map((oneMealNutrition) =>
        oneMealNutrition.forEach((item) => {
          if (item.name.toLowerCase() === "calories") {
            calories +=
              typeof item.amount === "string"
                ? parseInt(item.amount.split(" ")[0])
                : item.amount;
          } else {
            nutrients = {
              ...nutrients,
              [item.name]:
                (nutrients[item.name] || 0) +
                (typeof item.amount === "string"
                  ? parseInt(item.amount.split(" ")[0])
                  : item.amount),
            };
          }
        })
      );
    };

    Object.entries(apiData).forEach(([yearMonth, value]) => {
      Object.entries(value).forEach(([date, value]) => {
        if (
          nutritionType === "past" &&
          moment(`${yearMonth}-${date}`).isBefore(todayDate)
        ) {
          if (
            filterDays === null ||
            moment(`${yearMonth}-${date}`).isSameOrAfter(
              moment().subtract(filterDays, "days").format("YYYY-MM-DD")
            )
          ) {
            setNutrition(value);
          }
        } else if (
          nutritionType === "planned" &&
          moment(`${yearMonth}-${date}`).isSameOrAfter(todayDate)
        ) {
          if (
            filterDays === null ||
            moment(`${yearMonth}-${date}`).isSameOrBefore(
              moment().add(filterDays, "days").format("YYYY-MM-DD")
            )
          ) {
            setNutrition(value);
          }
        }
      });
    });
    const data = Object.entries(nutrients).map(([name, value]) => ({
      name,
      value,
    }));
    setData({
      loading: false,
      fetchedData: data,
      calories: calories.toFixed(2),
    });
  };

  function filterClickHandler(e) {
    if (filterDays !== parseInt(e.target.value)) {
      filterDays = parseInt(e.target.value);
      fetchNutrition();
    }
  }

  useEffect(() => {
    uid && hitApiAndFetch();
  }, []);

  return (
    <>
      <StyledFilters>
        <ToggleButtonGroup
          exclusive
          aria-label="max-ready-time"
          value={filterDays}
          onChange={filterClickHandler}
          className="filter-btns"
        >
          {tabs.map(
            (tab, index) =>
              typeof tab.value === "number" && (
                <ToggleButton
                  size="large"
                  aria-label={tab.name}
                  value={tab.value}
                  key={index}
                >
                  {tab.name}
                </ToggleButton>
              )
          )}
        </ToggleButtonGroup>
      </StyledFilters>
      <StyledChart>
        <img src={leaf} alt="alt" />
        {data.loading ? (
          <div className="loader no-data">
            <CircularProgress />
          </div>
        ) : data.fetchedData.length > 0 ? (
          <>
            <span className="calories">
              Total Calories: {data.calories} Kcal
            </span>
            <ResponsiveContainer>
              <PieChart width={250} height={250}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data.fetchedData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {data.fetchedData.map((entry, index) => (
                    <Cell
                      // style={{outline: "none"}}
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </>
        ) : (
          <div className="no-data">
            <h5>
              {nutritionType === "past"
                ? "No past meals in your calendar"
                : "No upcoming meals in your calendar"}
            </h5>
          </div>
        )}
        <div className="content">
          <div className="proteins">
            <h5>
              <span>∎</span>Protein
            </h5>
            <p>
              Essential for growth and repair of tissues, it is made up of amino
              acids and is commonly found in foods such as meat, dairy, beans
              and legumes.
            </p>
          </div>
          <div className="sugar">
            <h5>
              <span>∎</span>Sugar
            </h5>
            <p>
              Sugar, specifically glucose, plays a crucial role in the human
              body as it provides a significant source of energy. It fuels all
              cells in our body.
            </p>
          </div>
          <div className="fats">
            <h5>
              <span>∎</span>Fat
            </h5>
            <p>
              Essential for proper brain function and aiding in the absorption
              of certain vitamins. It is important to have a balance of
              different types of fats in your diet.
            </p>
          </div>
          <div className="carbs">
            <h5>
              <span>∎</span>Carbs
            </h5>
            <p>
              Carbohydrates are essential macronutrients found in various foods.
              They serve as a primary source of energy for the body.
            </p>
          </div>
        </div>
      </StyledChart>
    </>
  );
}

const StyledChart = styled.div`
  border-radius: 16px;
  box-shadow: 1px 1px 8px var(--shadow);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
  min-height: 340px;
  img {
    width: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .calories {
    position: absolute;
    top: 5%;
    left: 2%;
    white-space: nowrap;
  }
  .no-data,
  .recharts-responsive-container {
    flex-basis: 380px;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  .recharts-responsive-container {
    height: 300px !important;
    g:focus {
      border: none;
      outline: none;
    }
    text {
      font-size: 14px;
    }
  }
  .content {
    flex: 1 1 50%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    p {
      max-width: 35ch;
    }
    span {
      font-size: 20px;
    }
    .proteins {
      span {
        color: #0088fe;
      }
    }
    .sugar {
      span {
        color: #00c49f;
      }
    }
    .fats {
      span {
        color: #ffbb28;
      }
    }
    .carbs {
      span {
        color: #ff8042;
      }
    }
  }
  @media (max-width: 600px) {
    img {
      width: 40px;
      left: -3%;
    }
    .calories {
      top: 2%;
      left: 50%;
      transform: translateX(-50%);
    }
    .recharts-responsive-container {
      height: 210px !important;
      .recharts-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      svg {
        width: 100% !important;
        height: 160px !important;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .content {
      justify-content: center;
      text-align: left;
      p {
        max-width: 30ch;
      }
    }
  }
`;
const StyledFilters = styled.div`
  margin-bottom: 10px;
  .filter-btns {
    /* flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%; */
    button {
      min-width: 60px;
      background: transparent;
      font-size: 12px;
      font-weight: 600;
    }
    .Mui-selected {
      color: white;
      background-color: var(--green-color) !important;
    }
  }
  @media (pointer: fine) {
    .filter-btns {
      button:hover {
        color: white;
        background-color: var(--green-color);
      }
    }
  }
`;
