import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { db } from "../../authentication/firebase";
import { alertActions } from "../../store/alertSlice";
import { userActions } from "../../store/userSlice";
import { profilePreferenceItems } from "../../utils/profilePageData";
import ProfileItem from "./ProfileItem";
import logo from "../../assets/logo.svg";
import styled from "styled-components";
import { modalActions } from "../../store/modalSlice";

var forms = {};
export default function ProfileItems() {
  const [profileItemsIndex, setProfileItemsIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const { uid, preferences } = useSelector((state) => state.user);
  const navigate = useNavigate();

  async function submitHandler(formValues, addValueChange) {
    if (
      profileItemsIndex + addValueChange ===
      Object.keys(profilePreferenceItems).length
    ) {
      setLoading(true);
      console.log("items", forms);
      try {
        if(uid){
        await setDoc(
          doc(db, "userPreferences", uid),
          { ...forms, ...formValues },
          {
            merge: true,
          }
        );
        dispatch(userActions.setUserPreferences(forms));
        dispatch(
          alertActions.setAlert({
            title: "Preferences added successfully",
            messageType: "success",
          })
        );
        navigate("/profile-setup/pantry");
      } else {
        dispatch(modalActions.setLoginModalActive(true))
      }
        setLoading(false);
      } catch (error) {
        dispatch(
          alertActions.setAlert({
            title: "Failed to add preferences",
            messageType: "error",
          })
        );
        console.log("error", error)
      }
    } else if (profileItemsIndex + addValueChange !== -1) {
      forms = { ...forms, ...formValues };
      console.log("forms", forms, formValues)
      setProfileItemsIndex((prev) => {
        // const divisionNo = 100/profilePreferenceItems.length-1
        let temp = (prev + addValueChange) * 9;
        temp === 99 && temp++;
        setProgress(temp);
        return prev + addValueChange;
      });
    }
  }
  return (
    <StyledItems progress={progress}>
      <div className="progress-bar">
        <div>
          <h5>{progress}%</h5>
          <img src={logo} alt="break bread logo" />
        </div>
      </div>
      <div className="items">
        {Object.entries(profilePreferenceItems).map(([key, value], index) => (
          <ProfileItem
            name={key}
            options={value.values}
            inputType={value.inputType}
            submitHandler={submitHandler}
            key={index}
            style={{ display: index !== profileItemsIndex && "none" }}
            loading={loading}
          />
        ))}
      </div>
    </StyledItems>
  );
}
const StyledItems = styled.div`
  .progress-bar {
    position: relative;
    width: 80%;
    margin: 20px auto;
    border: 2px solid var(--shadow-light);
    border-radius: var(--radius);
    div {
      height: 12px;
      background-color: var(--primary-color);
      border-radius: 50px;
      width: ${(props) => `${props.progress}%`};
      img {
        width: 50px;
        position: absolute;
        left: ${(props) => `${props.progress - 1}%`};
        top: -300%;
      }
      h5 {
        color: white;
        position: absolute;
        padding: 2px 8px;
        top: -520%;
        left: ${(props) => `${props.progress - 0.2}%`};
        border-radius: 6px;
        background-color: var(--primary-color);
      }
    }
  }
  .items{
    /* padding: 30px 10px;
    border: 1px solid var(--shadow-light);
    border-radius: 24px; */
  }
`;
