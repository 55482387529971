import React from "react";
import LoadingButton from "../LoadingButton";
import { useState } from "react";
import useDeleteMeal from "../../hooks/useDeleteMeal";
import { DeleteOutline } from "@mui/icons-material";
import styled from "styled-components";
import BackdropWrapper from "../modals/BackdropWrapper";
import RecipeDetails from "../modals/RecipeDetails";

const mealTypes = ["🥞 Breakfast", "🥗 Lunch", "🥪 Dinner", "🍫 Snack"];
let selectedMealDetails = "";

export default function TitleOnlyRecipes({ meals, recipePlanDate }) {
  console.log("title only recipes")

  const [mealsLoading, setMealsLoading] = useState({
    breakfast: false,
    lunch: false,
    dinner: false,
    snack: false,
  });
  const [detailsBackdrop, setDetailsBackdrop] = useState(false);

  const deleteMeal = useDeleteMeal();

  async function deleteMealHandler(mealType) {
    setMealsLoading((prev) => ({ ...prev, [mealType]: true }));
    try {
      await deleteMeal(meals[mealType], mealType, recipePlanDate);
    } catch (error) {
      console.error("Error deleting meal:", error);
    }
    setMealsLoading((prev) => ({ ...prev, [mealType]: false }));
  }

  function backdropHandler() {
    setDetailsBackdrop((current) => !current);
  }

  function mealClickHandler(meal) {
    selectedMealDetails = meal;
    backdropHandler();
  }


  return (
    <StyledTitleOnly>
      <BackdropWrapper
        open={detailsBackdrop}
        handleBackdrop={backdropHandler}
        element={
          <RecipeDetails
            data={selectedMealDetails}
            handleBackdrop={backdropHandler}
          />
        }
      />
      {mealTypes.map((type, index) => {
        const mealTypeIcon = type.split(" ")[0];
        const mealType = type.split(" ")[1].toLowerCase();

        return (
          <div key={index} className="recipe">
            <h4 className="meal-icon">{mealTypeIcon}</h4>
            <div>
              <h5>{type.split(" ")[1]}</h5>
              {meals && meals[mealType] ? (
                <div className="meal-title">
                  <p
                    className="title"
                    onClick={(e) => mealClickHandler(meals[mealType])}
                  >
                    {meals[mealType].title}
                  </p>
                  <p>
                    ({meals[mealType]?.nutrition.nutrients[0].amount}
                    {meals[mealType]?.nutrition.nutrients[0].unit})
                  </p>
                  <LoadingButton
                    loading={mealsLoading[mealType]}
                    className="icon delete-btn"
                    onClick={(e) => deleteMealHandler(mealType)}
                    title={<DeleteOutline fontSize="small" htmlColor="gray" />}
                  />
                </div>
              ) : (
                <p>No meal planned</p>
              )}
            </div>
          </div>
        );
      })}
    </StyledTitleOnly>
  );
}

const StyledTitleOnly = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 4px auto;
  align-items: flex-start;
  text-align: left;
  .recipe {
    display: flex;
    gap: 6px;
    .meal-icon {
      width: 25px;
    }
    .meal-title {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      .title {
        max-width: 30ch;
        text-decoration: underline;
        cursor: pointer;
      }
      .delete-btn {
        svg {
          color: gray;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        &:hover {
          transform: scale(1.08);
        }
      }
    }
  }
`;
