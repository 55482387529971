import { collection, getDocs, query, where } from "firebase/firestore";
// import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../authentication/firebase";
import { userRecipesActions } from "../store/userRecipesSlice";

export default function useUserRecipes() {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.user.uid);
  // let userRecipes = [];
  const fetchUserRecipes = async (recipeTypes) => {
    try {
      return recipeTypes.forEach(async (recipeType) => {
        let fetchedDocs = [];
        if (uid) {
          const q = query(
            collection(db, `${recipeType}Recipes`),
            where("uid", "==", uid)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            fetchedDocs.push({ ...doc.data(), id: doc.id });
          });
        }
        console.log(recipeType, "recipes", fetchedDocs, uid);
        // userRecipes.push({
        //   data: [...fetchedDocs],
        //   recipeType,
        // });
        dispatch(
          userRecipesActions.setUserRecipes({
            data: [...fetchedDocs],
            recipeType,
          })
        );
        return fetchedDocs;
      });
      // return userRecipes
    } catch (error) {
      console.log(`error while fetching user recipes`, error);
    }
  };

  return fetchUserRecipes;
}
