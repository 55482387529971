import ViewAll from "../../styles/ViewAll";
import styled from "styled-components";
import leaf from "../../assets/dashboard/leaf.png";
import Calendar from "../meal-planning/Calendar";
import NutritionChart from "../NutritionChart";
import { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import RecipesLoading from "../recipes/RecipesLoading";

function StockandHistory() {
  const [generatedRecipes, setGeneratedRecipes] = useState({
    data: [],
    loading: false,
  });

  async function getUserRecipes() {
    setGeneratedRecipes({ data: [], loading: true });
    const recipeIds = ["dfd2e783cd026", "dd815887c0928", "3fe4e99712adb"]
    try {
      const q = query(
        collection(db, "gptRecipes"),
        // where("id", "in", recipeIds)
        // orderBy("generationDate", "desc"),
        limit(3)
      );
      const docsRef = await getDocs(q);
      let fetchedRecipes = [];
      docsRef.forEach((doc) => fetchedRecipes.push(doc.data()));
      console.log("fetch", fetchedRecipes);
      setGeneratedRecipes({ data: fetchedRecipes, loading: false });
    } catch (e) {
      setGeneratedRecipes({ data: [], loading: false });
      console.log(e);
    }
  }

  useEffect(() => {
    getUserRecipes();
  }, []);

  return (
    <StyledStockandHistory>
      <img src={leaf} alt="leaf" className="leaf" />
      <div className="stocks">
        <div className="column1">
          <div className="title">
            <h4>AI Generated Recipes</h4>
            <ViewAll navigate="/ai-recipes" />
          </div>
          {/* <div className="items">
            <div className="item item1">
              <img src={peas} alt="peas" />
              <h3>Peas</h3>
              <h5>Amount 2kg</h5>
            </div>
            <div className="item item2">
              <img src={lettuce} alt="lettuce" />
              <h3>Lettuce</h3>
              <h5>Amount 1kg</h5>
            </div>
            <div className="item item3">
              <img src={strawberry} alt="strawberry" />
              <h3>Strawberry</h3>
              <h5>Amount 3kg</h5>
            </div>
          </div> */}
          <RecipesLoading data={generatedRecipes} skeletonValue={3} />
        </div>
        <div className="column2">
          {/* <div className="title">
            <h4>Meal Planning</h4>
            <ViewAll navigate="meal-planning" />
          </div> */}
          <Calendar />
        </div>
      </div>
      <div className="history">
        <div className="title">
          <h4>Planned Meals Nutrition</h4>
          <ViewAll navigate="/meal-nutrition" />
        </div>
        <div className="info">
          {/* <div className="resume">
            <h4>Nutrition</h4>
            <h5>
              <img src={burger} alt="burger" /> Fat 15%
            </h5>
            <h5>
              <img src={candy} alt="candy" /> Sugar 11%
            </h5>
            <h5>
              <img src={protein} alt="protein" /> Protein 34%
            </h5>
            <h5>
              <img src={carbs} alt="carbs" /> Carbs 40%
            </h5>
          </div> */}
          <div className="chart">
            {/* <img src={plate} alt="plate" className="plate" /> */}
            {/* <img src={chart} alt="chart" /> */}
            <NutritionChart nutritionType="planned" />
          </div>
        </div>
      </div>
    </StyledStockandHistory>
  );
}

const StyledStockandHistory = styled.div`
  width: var(--tablet-width);
  margin: auto;
  margin-top: var(--section-margin);
  position: relative;
  .card-data {
    box-shadow: 1px 1px 8px var(--shadow);
  }
  .leaf {
    position: absolute;
    bottom: -25%;
    left: -6%;
    max-width: 10%;
    z-index: -1;
  }
  .stocks {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    /* align-items: center; */
    height: min-content;
    .column1 {
      flex: 1 1 70%;
    }
    .column2 {
      flex: 1 1 300px;
      justify-self: center;
      button {
        padding: 2px;
        min-width: 30px;
      }
      .fc-h-event {
        background-color: #00ba34;
        border-color: #00ba34;
      }
      .fc-button-group span {
        color: white;
      }
      .fc-daygrid-day-frame {
        cursor: pointer;
        &:hover {
          background-color: var(--yellow-color);
        }
      }
      img {
        margin-top: 10px;
        max-width: 380px;
      }
    }
  }
  .history {
    margin-top: var(--section-margin);
    .info {
      /* width: 90%; */
      margin: auto;
      margin-top: 40px;
      .resume {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
        margin-bottom: auto;
        border: 1px solid var(--shadow-light);
        border-radius: 12px;
        flex-basis: 12%;
        padding: 20px 0 20px 10px;
        background-color: #e7e7e7;
        h3 {
          font-weight: 600;
        }
        h5 {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          color: var(--text-color);
          font-weight: 500;
          img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }
      }
      .chart {
        position: relative;
        flex-basis: 88%;
      }
    }
  }
`;

export default StockandHistory;
