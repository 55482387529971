import { Configuration, OpenAIApi } from "openai";
import {
  recipeTitlesFormat,
  titlesWithIngredientsFormat,
  recipeShortFormat,
  recipeLongFormat,
} from "./gptFormats";
const PDFJS = require("pdfjs-dist/webpack");

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_CONVERT_TO_RECIPES_API_KEY,
});
const openai = new OpenAIApi(configuration);
const gptModel = "gpt-4o-2024-08-06";


let base64Images = [];

const encodeImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]); // Remove the "data:image/..." prefix
    reader.onerror = (error) => reject(error);
  });
};

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

const convertPdfToImages = async (file) => {
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    images.push(canvas.toDataURL());
  }
  // canvas.remove();
  return images;
};

const getImageMessagesforGpt = async (files) => {
  base64Images = [];
  let pdfFiles = [];
  files = files.filter((file) => {
    if (file.type === "application/pdf") {
      pdfFiles.push(file);
      return false;
    } else {
      return true;
    }
  });

  const pdfImages = await Promise.all(
    pdfFiles.map(async (curr) => await convertPdfToImages(curr))
  );

  // Flatten the array if each PDF returns multiple images
  const flatPdfImages = pdfImages.flat();

  console.log("pdf images", flatPdfImages);

  base64Images = await Promise.all(
    files.map((file) => encodeImageToBase64(file))
  );
  base64Images = base64Images.map((base64Image) => ({
    type: "image_url",
    image_url: {
      url: `data:image/jpeg;base64,${base64Image}`,
      detail: "high",
    },
  }));
  pdfImages.map((fullBase64Image) =>
    base64Images.push({
      type: "image_url",
      image_url: {
        url: fullBase64Image[0],
        detail: "high",
      },
    })
  );

  console.log("base64 images", base64Images);
};

export const fetchRecipeTitles = async (files) => {
  await getImageMessagesforGpt(files);
  const messages = [
    {
      role: "system",
      content: `When I attach any image or images. It should list out all the names of the recipes. Don't provide any names which are not in the image/images. If the image does not contain any recipe related information or an important information is missing from the recipe, then provide response with a json object with the property named 'error' which tells about the problem. Otherwise provide the response in following format: ${recipeTitlesFormat}`,
    },
    {
      role: "user",
      content: [
        {
          type: "text",
          text: `Provide only the name's of the recipe's in the attached image's. Don't provide the names of the recipes that are not attached in the images`,
        },
        ...base64Images,
      ],
    },
  ];
  const response = await openai.createChatCompletion({
    model: gptModel,
    response_format: { type: "json_object" },
    messages,
  });
  const parsed = JSON.parse(response.data.choices[0].message.content);
  if (parsed.error) {
    throw new Error(parsed.error);
  }
  return parsed;
};

export const fetchRecipeIngredients = async (recipeTitles) => {
  const messages = [
    {
      role: "system",
      content: `Attached image's has/have following recipe name's: ${recipeTitles}. Should only the name of the recipe mentioned in the attached image/images. It should list out all the ingredients of the recipes along with names. If the image does not contain any recipe related information or an important information is missing from the recipe, then provide response with a json object with the property named 'error' with tells about the problem. Otherwise provide the response in following format: ${titlesWithIngredientsFormat}`,
    },
    {
      role: "user",
      content: [
        {
          type: "text",
          text: `Provide the name's with ingredients of the recipe's in the attached image's`,
        },
        ...base64Images,
      ],
    },
  ];
  const response = await openai.createChatCompletion({
    model: gptModel,
    response_format: { type: "json_object" },
    messages,
  });

  const parsed = JSON.parse(response.data.choices[0].message.content);
  if (parsed.error) {
    throw new Error(parsed.error);
  }
  return parsed;
};

export const fetchShortRecipes = async (recipeTitles, recipeIngredients) => {
  const messages = [
    {
      role: "system",
      content: `Pick information of the recipe whose name=${recipeTitles} and ingredients=${JSON.stringify(
        recipeIngredients
      )} from attached image/images. Only provide one recipe. It should list out all the information with all the ingredients and steps and try to add the missing information as accurately as possible if it's missing some information. If the image does not contain any important recipe related information or an important information is missing from the recipe, then provide response with a json object with the property named 'error' with tells about the problem. Otherwise provide the response in exactly the following format: ${recipeShortFormat}`,
    },
    {
      role: "user",
      content: [
        {
          type: "text",
          text: `Provide the recipe's information in the attached image's`,
        },
        ...base64Images,
      ],
    },
  ];
  const response = await openai.createChatCompletion({
    model: gptModel,
    response_format: { type: "json_object" },
    messages,
  });

  const parsed = JSON.parse(response.data.choices[0].message.content);
  if (parsed.error) {
    throw new Error(parsed.error);
  }
  return parsed;
};

export const fetchLongRecipes = async (recipeTitles, recipeIngredients) => {
  const messages = [
    {
      role: "system",
      content: `Pick information of the recipe whose name=${recipeTitles} and ingredients=${JSON.stringify(
        recipeIngredients
      )} from attached image/images. Only provide one recipe. It should list out all the information with all the ingredients and nutrition and try to add the missing information as accurately as possible  if it's missing some information. If the image does not contain any important recipe related information or an important information is missing from the recipe, then provide response with a json object with the property named 'error' which tells about the problem. Otherwise provide the response in exactly the following json format: ${recipeLongFormat}`,
    },
    {
      role: "user",
      content: [
        {
          type: "text",
          text: `Convert the information given in the attached images into the json array of object's format.`,
        },
        ...base64Images,
      ],
    },
  ];

  const response = await openai.createChatCompletion({
    model: gptModel,
    response_format: { type: "json_object" },
    messages,
  });

  const parsed = JSON.parse(response.data.choices[0].message.content);
  if (parsed.error) {
    throw new Error(parsed.error);
  }
  return parsed;
};
