import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RecipeButtons from "../recipes/RecipeButtons";
// import { useSelector } from "react-redux";
import RecipesLoading from "../recipes/RecipesLoading";
import LoadingButton from "../LoadingButton";
// import QuickPersonalize from "../modals/QuickPersonalize";
// import BackdropWrapper from "../modals/BackdropWrapper";
import { useMediaQuery } from "@mui/material";
// import firebaseApi from "../../utils/firebaseApi";
import { searchAlgolia } from "../../utils/algoliaFunctions";
import useDatabaseRecipes from "../../hooks/useDatabaseRecipes";
import { getDocIds } from "../../utils/cloudFunctions";

function DashboardHome() {
  // const { fullname, preferences, email } = useSelector((state) => state.user);
  const [input, setInput] = useState({
    query: "",
    noOfServings: 1,
    dietRestriction: "none",
  });
  const [fetchedRecipes, setFetchedRecipes] = useState({
    data: [],
    loading: false,
  });
  // const { fetchRecipes } = useDatabaseRecipes("gptRecipes", 5);
  // const [backdropOpened, setBackdropOpened] = useState(true);
  // const { fetchedRecipes, setFetchedRecipes, fetchRecipes } = useFetch(
  //   "/complexSearch",
  //   5
  // );
  const mediaQuery = useMediaQuery("(min-width: 950px)");
  const mediaQueryBtn = useMediaQuery("(min-width: 600px)");

  function inputChangeHandler(e) {
    setInput((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  }

  async function inputSubmitHandler(e) {
    e.preventDefault();
    if (input.query.length > 0) {
      // await fetchRecipes({
      //   query: input.query,
      //   number: 5,
      //   filter: true,
      // });
      fetchAlgoliaRecipes(input.query);
    }
  }

  async function fetchAlgoliaRecipes(query) {
    try {
      setFetchedRecipes({ data: [], loading: true });
      const hits = await searchAlgolia({query, noOfRecipes: 5});
      setFetchedRecipes({
        data: hits,
        loading: false,
      });
    } catch (e) {
      console.log("error while fetching algolia recipes", e);
    }
  }

  useEffect(() => {
    fetchAlgoliaRecipes();
  }, []);
  // useEffect(() => {
  //   const sendSignupEmailAlert = async () => {
  //     try {
  //       const res = await firebaseApi.get(
  //         `/sendSignupAlertEmail?email=${email}`
  //       );
  //       console.log("res", res.data);
  //     } catch (e) {
  //       console.log("error", e);
  //     }
  //   };
  //   Object.keys(preferences)?.length === 0 && sendSignupEmailAlert();
  // }, []);

  return (
    <>
      {/* {Object.keys(preferences)?.length === 0 && (
        <BackdropWrapper
          open={backdropOpened}
          element={<QuickPersonalize setBackdropOpened={setBackdropOpened} />}
        />
      )} */}
      <StyledHome>
        <div className="style-div" />
        {mediaQueryBtn && (
          <div className="home-buttons">
            <RecipeButtons title="" />
          </div>
        )}
        <h1>Welcome 👋🏼</h1>
        <h4>Quick Search Recipes</h4>
        <StyledSearch onSubmit={inputSubmitHandler}>
          {/* <textarea
            required
            value={input.query}
            name="query"
            onChange={inputChangeHandler}
            placeholder={"Search recipes here"}
          /> */}
          <input
            type="text"
            name="query"
            value={input.query}
            onChange={inputChangeHandler}
            placeholder="Search recipes"
          />
          <LoadingButton
            loading={fetchedRecipes?.loading}
            type="submit"
            title="Search"
          />
        </StyledSearch>
        <div className="recipes">
          <RecipesLoading data={fetchedRecipes} />
        </div>
      </StyledHome>
    </>
  );
}
const StyledHome = styled.div`
  width: var(--tablet-width);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 10px;
  h1 {
    margin-top: 20px;
  }
  h4 {
    color: var(--secondary-color);
  }
  .style-div {
    width: 100vw;
    height: 640px;
    position: absolute;
    top: -340px;
    /* left: -5.6%; */
    background-color: rgb(212, 242, 200, 0.8);
    border-radius: 50%;
    z-index: -1;
  }
  .home-buttons {
    width: 100%;
    margin-top: 20px;
  }
  .recipes {
    width: 100%;
    position: relative;
    text-align: center;
    .card-recipes {
      border: none;
      box-shadow: none;
    }
  }
  @media screen and (max-width: 450px) {
    h1 {
      font-size: 2rem;
    }
  }
`;
const StyledSearch = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: inherit;
  gap: 10px;
  margin: 10px auto;
  h4 {
    text-align: center;
  }
  input {
    width: 400px;
  }
  .filters {
    display: flex;
    gap: 2rem;
    label {
      display: flex;
      flex-direction: column;
      align-items: center;
      input,
      select {
        border-radius: 4px;
      }
      input[type="number"] {
        width: 70px;
        height: 38.4px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    input {
      width: 320px;
    }
    textarea {
      min-width: 300px;
      max-width: 400px;
    }
  }
`;

export default DashboardHome;
