import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import NutritionChart from "../components/NutritionChart";

function MealsNutrition() {
  return (
    <>
      <Nav />
      <StyledHistory>
        <h3>Meal Nutrition</h3>
        <div className="charts">
          <h4>Upcoming Meals Nutrition</h4>
          <div className="chart">
            <NutritionChart nutritionType="planned" />
          </div>
          <h4>Past Meals Nutrition</h4>
          <div className="chart">
            <NutritionChart nutritionType="past" />
          </div>
        </div>
      </StyledHistory>
      <Footer />
    </>
  );
}

const StyledHistory = styled.section`
  width: var(--tablet-width);
  margin: var(--section-margin) auto 0 auto;
  h3{
    margin-bottom: var(--heading-margin-sm);
  }
  .pie-chart {
    width: 100%;
    max-height: 40vh;
  }
  .charts {
    margin: 30px auto;

    .chart,
    .img-div {
      margin: 40px auto;
    }
    .img-div {
      img {
        width: 100%;
      }
    }
  }
  .previous-recipes {
    width: 100%;
    margin: auto;
    .rated-items {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-top: 40px;
    }
  }
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export default MealsNutrition;
