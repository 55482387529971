import { useMediaQuery } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

export default function AiRecipeGenerating({ recipes }) {
  const mediaQuery = useMediaQuery("(max-width: 600px)");
  const initialRecipes = { ...recipes };
  if (Object.values(recipes)[0].length > 0) {
    Object.entries(recipes).forEach(([title, ingredients]) => {
      initialRecipes[title] = ingredients.map(
        (ingredient) => ingredient.nameClean
      );
    });
  }


  return (
    <StyledGenerating className="generation-loading">
      {Object.entries(initialRecipes)?.map(([title, ingredients], index) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { opacity: { delay: index * 3 } },
          }}
          key={index}
          style={{
            width: mediaQuery ? 280 : 180,
            height: mediaQuery ? 100 : 205,
          }}
          className="box"
        >
          <h5>{title}</h5>
          <Typewriter
            options={{
              strings: [
                ingredients.length > 0
                  ? ingredients.join("<br>")
                  : "Generating...",
              ],
              autoStart: true,
              pauseFor: 20000,
            }}
          />
        </motion.div>
      ))}
    </StyledGenerating>
  );
}

const StyledGenerating = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 20px;
  column-gap: 30px;
  margin: 20px 0;
  /* border: 1px solid var(--shadow-light); */
  padding: 20px 10px;
  border-radius: 24px;
  /* box-shadow: 1px 1px 8px var(--shadow); */
  .box {
    padding: 4px 0;
    box-shadow: 0px 0px 8px 3px var(--shadow);
    border-radius: var(--radius);
    overflow-y: hidden;
    h5 {
      padding: 2px 4px;
      border-bottom: 1px solid var(--text-color);
    }
    .Typewriter {
      padding: 2px 4px;
    }
  }
`;
