import React from "react";
import { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

export default function Servings({
  handleBackdrop,
  title,
  recipeServings,
  dropHandler,
  draggedMealType,
}) {
  const [servings, setServings] = useState(recipeServings);
  function handleChange(e) {
    setServings(e.target.value);
  }
  function setServingsHandler(e) {
    e.preventDefault();
    handleBackdrop();
    dropHandler(draggedMealType, servings);
  }
  return (
    <StyledServings>
      <div className="heading">
        <h4>{title}</h4>
        <button className="icon" onClick={(e) => handleBackdrop(false)}>
          {<CloseIcon htmlColor="#F79C16" fontSize="large" />}
        </button>
      </div>
      <form className="content" onSubmit={setServingsHandler}>
        <label>
          <h5>Select no. of servings</h5>
          <input
            type="number"
            min="1"
            max="100"
            step="1"
            name="servings"
            autoFocus
            defaultValue={recipeServings}
            value={servings}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Add</button>
      </form>
    </StyledServings>
  );
}

const StyledServings = styled.section`
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    span {
      max-width: 35ch;
      text-align: left;
      font-weight: bold;
    }
    input {
      width: 100%;
    }
  }
`;
