import React, { useState } from "react";
import styled from "styled-components";
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import LocalDiningRoundedIcon from '@mui/icons-material/LocalDiningRounded';
import BackdropWrapper from "../modals/BackdropWrapper";
import RecipeForm from "../modals/RecipeForm";
import { useMediaQuery } from "@mui/material";

function RecipeButtons({ title }) {
  const [backdrop, setBackdrop] = useState(false);
  const [recipeType, setRecipeType] = useState("web");
  const mediaQuery = useMediaQuery("(min-width: 950px)");

  const recipeHandler = (e, type) => {
    setRecipeType(type);
    setBackdrop(!backdrop);
  };

  return (
    <StyledButtons>
      <BackdropWrapper
        open={backdrop}
        handleBackdrop={recipeHandler}
        element={
          <RecipeForm recipeType={recipeType} handleBackdrop={recipeHandler} />
        }
      />
      <h3>{title}</h3>
      <div className="buttons">
        {/* <button onClick={(e) => recipeHandler(e, "web")}>
          <RestaurantOutlinedIcon fontSize="small" /> Add web recipe
        </button> */}
        <button onClick={(e) => recipeHandler(e, "manual")}>
          <LocalDiningRoundedIcon fontSize="small" /> Add custom recipe
        </button>
      </div>
    </StyledButtons>
  );
}

const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: var(--heading-margin-sm);
  .buttons {
    display: flex;
    gap: 20px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: var(--secondary-color);
      &:hover {
        background-color: var(--secondary-dark-color);
      }
    }
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export default RecipeButtons;
