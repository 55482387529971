import { CircularProgress, useMediaQuery } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { db } from "../../authentication/firebase";
import { mealPlanningActions } from "../../store/mealPlanningSlice";
import RecipeCard from "../recipes/RecipeCard";
import useAddRecipe from "../../hooks/useAddRecipe";
import Servings from "../modals/Servings";
import BackdropWrapper from "../modals/BackdropWrapper";
import moment from "moment";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import TitleOnlyRecipes from "../recipes/TitleOnlyRecipes";
import useAddStackedRecipe from "../../hooks/useAddStackedRecipe";

const mealTypes = ["🥞 Breakfast", "🥗 Lunch", "🥪 Dinner", "🍫 Snack"];
let draggedMealType = "";
export default function DropMeals({ dragStartHandler, date }) {
  const mediaQuery = useMediaQuery("(max-width: 900px)");
  const [selectedDate, setSelectedDate] = useState(date);
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.user.uid);
  const meals = useSelector(
    (state) => state.mealPlanning.monthMeals[selectedDate]
  );
  const [mealsLoading, setMealsLoading] = useState({
    breakfast: false,
    lunch: false,
    dinner: false,
    snack: false,
  });
  const [backdrop, setBackdrop] = useState(false);
  const addRecipeHandler = useAddRecipe();
  const addStackedRecipe = useAddStackedRecipe();

  const draggedRecipe = useSelector(
    (state) => state.mealPlanning.draggedRecipe
  );

  const fetchMeals = useCallback(
    async (yy, mm) => {
      setMealsLoading({
        breakfast: true,
        lunch: true,
        dinner: true,
        snack: true,
      });
      try {
        const collectionRef = collection(db, `dailyMeals/${uid}/${yy}-${mm}`);
        const monthData = await getDocs(collectionRef);
        let fetchedDocs = {};
        monthData.forEach((data) => {
          fetchedDocs[`${yy}-${mm}-${data.id}`] = data.data();
        });
        console.log("fetched month meals", fetchedDocs);
        dispatch(mealPlanningActions.monthMeals(fetchedDocs));
      } catch (error) {
        console.log("error", error);
      }
      setMealsLoading({
        breakfast: false,
        lunch: false,
        dinner: false,
        snack: false,
      });
    },
    [uid, dispatch]
  );

  const onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  function dragEnterHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add("drag-active");
  }
  function dragLeaveHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove("drag-active");
  }

  function handleBackdrop(mealType) {
    setBackdrop(!backdrop);
    if (mealType) draggedMealType = mealType;
  }

  const dropHandler = async (mealType, selectedServings) => {
    console.log("in", mealType, selectedServings);
    setMealsLoading((current) => ({ ...current, [mealType]: true }));
    addRecipeHandler(selectedDate, draggedRecipe, mealType, selectedServings)
      .then((e) => {
        window.dataLayer.push({
          event: "recipe-added-via-drop",
          recipeId: draggedRecipe.id,
        });
        dispatch(mealPlanningActions.setDraggedRecipe({}));
        setMealsLoading((current) => ({ ...current, [mealType]: false }));
      })
      .catch((e) => {
        setMealsLoading((current) => ({ ...current, [mealType]: false }));
        console.log("e", e);
      });
  };

  function dateChangeHandler(changeValue) {
    let newDate = moment(selectedDate);
    changeValue === "add"
      ? newDate.add(1, "days")
      : newDate.subtract(1, "days");

    newDate = newDate.format("YYYY-MM-DD");
    setSelectedDate(newDate);
    console.log("new date", newDate);
    fetchMeals(newDate.split("-")[0], newDate.split("-")[1]);
  }

  useEffect(() => {
    async function addMealsHandler() {
      const stackedRecipe = JSON.parse(localStorage.getItem("stackedRecipe"));
      console.log("sta", stackedRecipe);
      stackedRecipe?.id && (await addStackedRecipe());
      await fetchMeals(
        selectedDate?.split("-")[0],
        selectedDate?.split("-")[1]
      );
      localStorage.removeItem("stackedRecipe");
    }
    if (uid && meals === undefined) {
      addMealsHandler();
    }
  }, [uid, meals, fetchMeals]);

  return (
    <StyledFloatBox mediaQuery={mediaQuery}>
      <div className="date">
        <h5 className="heading">
          {moment(selectedDate).format("dddd, MMM Do, YYYY")}
        </h5>
        <div className="navigation">
          <button onClick={(e) => dateChangeHandler("subtract")}>
            <KeyboardArrowLeftIcon />
          </button>
          <button onClick={(e) => dateChangeHandler("add")}>
            <KeyboardArrowRightIcon />
          </button>
        </div>
      </div>
      {mediaQuery ? (
        <TitleOnlyRecipes meals={meals} recipePlanDate={date} />
      ) : (
        <StyledDropMeals className="meal-boxes">
          <BackdropWrapper
            open={backdrop}
            handleBackdrop={handleBackdrop}
            smallSize={true}
            element={
              <Servings
                handleBackdrop={handleBackdrop}
                title={draggedRecipe?.title}
                recipeServings={draggedRecipe?.servings}
                dropHandler={dropHandler}
                draggedMealType={draggedMealType}
              />
            }
          />
          {mealTypes.map((mealType, index) => {
            const mealTypeLowercase = mealType.split(" ")[1].toLowerCase();
            return (
              <div className="box" key={index}>
                <div className="header">
                  <h5>{mealType}</h5>
                </div>
                <div
                  onDragEnter={dragEnterHandler}
                  onDragLeave={dragLeaveHandler}
                  onDragOver={onDragOver}
                  onDrop={
                    (e) => handleBackdrop(mealTypeLowercase)
                    // dropHandler(mealTypeLowercase, draggedRecipe.servings)
                  }
                  className="item"
                >
                  {meals &&
                  !mealsLoading[mealTypeLowercase] &&
                  meals[mealTypeLowercase] !== undefined ? (
                    <RecipeCard
                      functionality={false}
                      deleteAble={true}
                      mealType={mealTypeLowercase}
                      data={{ ...meals[mealTypeLowercase] }}
                      dragStartHandler={(e) =>
                        dragStartHandler(meals[mealTypeLowercase])
                      }
                      selectedDate={selectedDate}
                    />
                  ) : mealsLoading[mealTypeLowercase] ? (
                    <p>
                      <CircularProgress
                        style={{
                          color: "inherit",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </p>
                  ) : (
                    <p className="drag-info">Drag & drop recipe here</p>
                  )}
                </div>
              </div>
            );
          })}
        </StyledDropMeals>
      )}
    </StyledFloatBox>
  );
}

const StyledFloatBox = styled.div`
  margin: 10px auto;
  padding: 0 16px;
  border: 1px solid var(--shadow-light);
  border-radius: 24px;
  box-shadow: 1px 2px 8px var(--shadow);
  gap: 10px;
  position: ${(props) => (props.mediaQuery ? "static" : "sticky")};
  top: 60px;
  z-index: 10;
  background-color: var(--background-color);
  .drag-info {
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary-dark-color);
  }
  h5 {
    font-weight: 500;
  }
  .heading {
    font-weight: 600;
  }
  .date {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 8px 0;
    gap: 0.6rem;
    .navigation {
      display: flex;
      button {
        background-color: #2c3e50;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        min-width: 25px;
        height: 25px;
        max-height: 25px;
        padding: 0;
        &:hover {
          background-color: #1a252f;
        }
        &:nth-child(1) {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:nth-child(2) {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    padding: 10px 0;
    gap: 10px;
    background-color: var(--yellow-color);
    border-radius: var(--radius);
    border: 2px dashed var(--secondary-light-color);
    p {
      line-height: 1.2;
      max-width: 30ch;
    }
    .img {
      flex-basis: 45%;
      img {
        max-width: 100%;
      }
    }
  }
  .drag-active {
    background-color: #e5f2fe;
    border-color: #2196f378;
  }
`;

const StyledDropMeals = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .box {
    margin-bottom: 10px;
  }
  h5 {
    font-size: 1rem;
  }
  p {
    font-size: 0.9rem !important;
  }
`;
