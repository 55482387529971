import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import ProfileTabs from "../components/profile-settings/ProfileTabs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deleteDoc, doc, setDoc, writeBatch } from "firebase/firestore";
import { db } from "../authentication/firebase";
import axios from "axios";
import firebaseApi from "../utils/firebaseApi";

function ArchiveList() {
  const [archiveList, setArchiveList] = useState({
    loading: true,
    data: {},
  });
  const navigate = useNavigate();
  const uid = useSelector((state) => state.user.uid);

  const fetchArchiveList = useCallback(async () => {
    try {
      const url = `https://us-central1-break-bread-web-app.cloudfunctions.net/getSubCollectionsData?docPath=cartArchiveList/${uid}`;
      const response = await axios.get(url);
      console.log("response", response.data);
      setArchiveList({ loading: false, data: response.data });
    } catch (error) {
      setArchiveList({ loading: false, data: {} });
      console.log("error", error);
    }
  }, []);

  const activeArchive = async () => {
    setArchiveList((current) => ({ loading: true, data: current.data }));
    try {
      // await setDoc(doc(db, "shoppingCart", uid), archiveList.data, {
      //   merge: true,
      // });
      // await deleteDoc(doc(db, "cartArchiveList", uid));
      const batch = writeBatch(db);
      // // set collection
      Object.entries(archiveList.data).forEach(([month, item]) => {
        Object.entries(item).forEach(async ([key, value]) => {
          const docRef = doc(db, `shoppingCart/${uid}/${month}`, key);
          await batch.set(docRef, value, { merge: true });
        });
      });
      await batch.commit();
      const url = `/deleteCollection?docPath=cartArchiveList/${uid}`;
      await firebaseApi.get(url);
      setArchiveList({ loading: false, data: {} });
    } catch (e) {
      console.log("error", e);
      setArchiveList((current) => ({ loading: false, data: current.data }));
    }
  };

  useEffect(() => {
    uid && fetchArchiveList();
  }, [uid]);
  return (
    <>
      <Nav />
      <StyledArchive>
        <div className="title">
          <h3>Archive List</h3>
          <button className="gray-btn" onClick={(e) => activeArchive()}>
            Active
          </button>
        </div>
        <div className="items">
          {archiveList.loading ? (
            <div className="loader">
              <CircularProgress />
            </div>
          ) : Object.keys(archiveList.data).length > 0 ? (
            Object.entries(archiveList.data).map(([key, value], index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        color: "#002140",
                        width: "38px",
                        height: "38px",
                      }}
                    />
                  }
                >
                  <div className="profile-tab-header">
                    <h4>
                      {key.split("-")[1]} / {key.split("-")[0]}
                    </h4>
                    {/* <button
                      className="gray-btn"
                      onClick={(e) => activeArchive(key)}
                    >
                      Active
                    </button> */}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(value).map(([key, value], index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: "#002140",
                              width: "38px",
                              height: "38px",
                            }}
                          />
                        }
                      >
                        <h5>{key}</h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="items-div">
                          {/* <ProfileTabs editable={false} tabData={value} /> */}
                          <ul>
                            {Object.values(value).map((ingredients) =>
                              Object.values(ingredients).map((ingredient) => (
                                <li>{ingredient.nameClean}</li>
                              ))
                            )}
                          </ul>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <div className="loader">
              <h5>No archive item found</h5>
            </div>
          )}
        </div>
      </StyledArchive>
      <Footer />
    </>
  );
}

const StyledArchive = styled.div`
  width: var(--tablet-width);
  margin: auto;
  margin-top: var(--section-margin);
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 20px;
  }
  .profile-tab-header {
    h4 {
      display: inline-block;
      margin-right: 20px;
    }
  }
  .items {
    padding-top: 20px;
    .loader {
      margin-top: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default ArchiveList;
