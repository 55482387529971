import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import styled from "styled-components";
import { auth } from "./firebase";
import { useLocation } from "react-router";

export default function SignInWithoutEmail() {
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();

  const location = useLocation();
  let { from } = location.state || { from: { pathname: "/dashboard" } };

  const signInHandler = async (provider) => {
    try {
      localStorage.setItem("routePath", from.pathname);
      await signInWithRedirect(auth, provider);
    } catch (e) {
      console.log("error while redirecting", e);
    }
  };

  return (
    <StyledSignIn>
      <button
        onClick={() => signInHandler(googleProvider)}
        className="google-button google-btn-gtag"
      >
        <FcGoogle /> Continue with Google
      </button>
      <button
        onClick={() => signInHandler(facebookProvider)}
        className="google-button google-btn-gtag"
      >
        <FaFacebook color="#3877ea" /> Continue with Facebook
      </button>
    </StyledSignIn>
  );
}

const StyledSignIn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin: .6rem 0;
`;
