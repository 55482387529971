import styled from "styled-components";
import { FaFacebook, FaYoutube, FaLinkedin, FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import logo from "../assets/named-logo.svg";
import moment from "moment";
import { useMediaQuery } from "@mui/material";

function Footer() {
  const year = moment(new Date()).format("yyyy");
  const mediaQuery = useMediaQuery("(min-width: 900px)");

  if (mediaQuery)
    return (
      <StyledFooter id="faq">
        <div className="copyright">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <h5>BreakBread © {year}</h5>
          <div className="social-links">
            <a
              className="linkedIn-gtag"
              target="_blank"
              href="https://www.linkedin.com/company/break-bread-inc/"
            >
              <FaLinkedin />
            </a>
            <a
              className="facebook-gtag"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100086959720979"
            >
              <FaFacebook />
            </a>
            <a
              className="instagram-gtag"
              target="_blank"
              href="https://www.instagram.com/breakbreadai"
            >
              <AiFillInstagram />
            </a>
            <a
              className="tiktok-gtag"
              target="_blank"
              href="https://www.youtube.com/@breakbread8836"
            >
              <FaTiktok />
            </a>
            <a
              className="youtube-gtag"
              target="_blank"
              href="https://www.youtube.com/@breakbread8836"
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </StyledFooter>
    );
}

const StyledFooter = styled.div`
  height: 10vh;
  position: absolute;
  width: 100%;
  bottom: -10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h5 {
    color: var(--primary-color);
  }
  .links {
    img {
      height: 50px;
    }
  }
  .copyright {
    position: absolute;
    width: 95%;
    bottom: 0;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: .5% 0;
    a {
      margin-right: 14px;
    }
    svg {
      width: 26px;
      height: 26px;
      color: var(--primary-color);
    }
    .logo,
    .social-links {
      flex-basis: 40%;
      display: flex;
    }
    .logo {
      img {
        max-width: 100px;
        margin-right: auto;
      }
    }
    .social-links{
      justify-content: flex-end;
    }
  }
`;
export default Footer;
