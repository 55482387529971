import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { auth } from "../authentication/firebase";
import LoadingButton from "../components/LoadingButton";
import { alertActions } from "../store/alertSlice";
import { useNavigate } from "react-router";

export default function DeleteUserData() {
  const { displayName, email, photoURL } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function deleteDataHandler() {
    try {
      setLoading(true);
      const user = auth.currentUser;
      await user.delete();
      dispatch(
        alertActions.setAlert({
          title: "User data deleted",
          messageType: "warning",
        })
      );
      navigate("/login");
    } catch (e) {
      console.log("error while deleting user", e.message);
      dispatch(
        alertActions.setAlert({
          title: JSON.parse(JSON.stringify(e)).code,
          messageType: "warning",
        })
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Nav />
      <StyledDeleteData>
        <h3>Delete User Data</h3>
        <div className="profile">
          <div className="profile-img">
            <Avatar src={photoURL} sx={{ width: "160px", height: "160px" }} />
          </div>
          <div>
            <h5>
              {displayName
                ?.split(" ")
                .map(
                  (name) => name.charAt(0).toUpperCase() + name.slice(1) + " "
                )}
            </h5>
            <span>{email}</span>
          </div>
          <LoadingButton
            loading={loading}
            title={"Delete my data"}
            className="blue-btn"
            onClick={deleteDataHandler}
          />
        </div>
      </StyledDeleteData>
      <Footer />
    </>
  );
}

const StyledDeleteData = styled.div`
  width: var(--tablet-width);
  margin: var(--section-margin) auto 0 auto;

  .profile {
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    gap: 10px;
    .profile-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      img {
        border: 1px solid var(--text-color);
      }
      label {
        cursor: pointer;
        font-size: 16px;
      }
      div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
      }
      button {
        flex-direction: row;
      }
    }
  }
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;
