import React, { useEffect } from "react";
import styled from "styled-components";
import demoImg from "../../assets/demoImg.png";
import { useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../authentication/firebase";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import BackdropWrapper from "../modals/BackdropWrapper";
import RecipeDetails from "../modals/RecipeDetails";
import QuickAddRecipe from "../modals/QuickAddRecipe";
import { Delete, DeleteOutline } from "@mui/icons-material";
import { userRecipesActions } from "../../store/userRecipesSlice";
import LoadingButton from "../LoadingButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FavoriteRecipeHandler from "./FavoriteRecipeHandler";
import useDeleteMeal from "../../hooks/useDeleteMeal";
import { motion } from "framer-motion";
import { mealPlanningActions } from "../../store/mealPlanningSlice";

function RecipeCard({
  data,
  functionality,
  deleteAble,
  mealType,
  addBtn,
  selectedDate,
}) {
  const [detailsBackdrop, setDetailsBackdrop] = useState(false);
  const [addRecipeBackdrop, setAddRecipeBackdrop] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const [deleteLoading, setDeleteLoading] = useState();

  const dispatch = useDispatch();

  const deleteMeal = useDeleteMeal();

  const mediaQuery = useMediaQuery("(max-width: 440px)");

  const menuClickHandler = () => {
    setDetailsBackdrop(!detailsBackdrop);
  };
  const addClickHandler = () => {
    setAddRecipeBackdrop(!addRecipeBackdrop);
  };
  const removeClickHandler = async () => {
    console.log("docId", data.id);
    setDeleteLoading(true);
    try {
      await deleteDoc(doc(db, `${data.recipeType}Recipes`, data.id));
      data.recipeType === "web"
        ? dispatch(
            userRecipesActions.deleteUserRecipe({ data, recipeType: "web" })
          )
        : dispatch(
            userRecipesActions.deleteUserRecipe({ data, recipeType: "manual" })
          );
    } catch (error) {
      console.log("error", error);
    }
    setDeleteLoading(false);
  };
  const deleteMealHandler = async () => {
    setDeleteLoading(true);
    await deleteMeal(data, mealType, selectedDate);
    setDeleteLoading(false);
  };

  useEffect(() => {
    if (data.image === undefined || data.image === null) {
      console.log("image not available");
      setImageLoading(false); // Handle empty URL (optional)
      return; // Exit useEffect early if URL is empty
    }

    const image = new Image();
    image.onload = () => {
      setImageLoading(false);
    };
    image.onerror = (e) => {
      console.log("image error", e);
      setImageLoading(false);
    }; // Handle error
    image.src = data.image;
  }, [data.image]);

  function dragStartHandler() {
    dispatch(mealPlanningActions.setDraggedRecipe(data));
  }

  return (
    <StyledRecipe
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      onDragStart={dragStartHandler}
      draggable
      // deleteAble={deleteAble}
      functionality={functionality}
    >
      <BackdropWrapper
        open={detailsBackdrop}
        handleBackdrop={menuClickHandler}
        element={
          <RecipeDetails
            data={data}
            selectedDate={selectedDate}
            handleBackdrop={menuClickHandler}
          />
        }
      />
      <BackdropWrapper
        open={addRecipeBackdrop}
        handleBackdrop={addClickHandler}
        element={
          <QuickAddRecipe
            selectedDate={selectedDate}
            data={data}
            handleBackdrop={addClickHandler}
          />
        }
      />

      <div className="img" onClick={menuClickHandler}>
        <LazyLoadImage
          effect="blur"
          width={mediaQuery ? 140 : 180}
          height={mediaQuery ? 100 : 125}
          placeholderSrc={demoImg}
          src={data.image || demoImg}
          loading="lazy"
          alt="break bread"
        />
        {(data.imageGenerating || imageLoading) && (
          <div className="image-loading">
            <CircularProgress
              style={{
                color: "var(--primary-dark-color)",
                width: 25,
                height: 25,
              }}
            />
          </div>
        )}

        {/* <img src={data.image ? data.image : demoImg} alt="" /> */}
        {/* {(data.image === null) && (
          <span className="image-loading">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("loading image...")
                  // .pauseFor(3000)
                  // .deleteAll()
                  // .typeString("generating image...")
                  .start();
              }}
            />
          </span>
        )} */}
        {data.recipeType === "gpt" && (
          <div className="ai-tag">
            <span>ai</span>
          </div>
        )}
      </div>
      <span className="header" onClick={menuClickHandler}>
        {mediaQuery
          ? data?.title?.length > 55
            ? data?.title?.substring(0, 55) + "..."
            : data?.title
          : data?.title?.length > 35
          ? data?.title?.substring(0, 35) + "..."
          : data?.title}
      </span>
      {addBtn !== false && <FavoriteRecipeHandler data={data} />}
      {deleteAble === true && (
        <LoadingButton
          loading={deleteLoading}
          className="icon delete-btn"
          onClick={deleteMealHandler}
          title={
            <Delete fontSize="small" htmlColor="var(--gray-light-color)" />
          }
        />
      )}
      {functionality !== false && (
        <div className="info btns">
          <div className="time">
            <AccessTimeIcon fontSize="small" htmlColor="#F79C16" />
            <p>{data?.readyInMinutes}min</p>
          </div>
          {(data.recipeType === "web" || data.recipeType === "manual") && (
            <LoadingButton
              loading={deleteLoading}
              className="icon delete-btn"
              onClick={removeClickHandler}
              title={
                <Delete fontSize="small" color="var(--gray-light-color)" />
              }
            />
          )}
          {addBtn !== false && (
            <LoadingButton
              title={"Add"}
              className="add-btn"
              onClick={addClickHandler}
              loading={!data.nutrition}
            />
          )}
        </div>
      )}
    </StyledRecipe>
  );
}

const StyledRecipe = styled(motion.div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  background-color: var(--background-color);
  border-radius: var(--radius);
  position: relative;
  width: 180px;
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 0px 8px 3px var(--shadow);
  overflow: hidden;
  &:hover {
    transform: scale(1.03);
  }
  .img {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    cursor: pointer;
    border-bottom: 1px solid lightgray;
    img {
      height: fit-content;
      max-height: 130px;
      align-self: center;
      flex-basis: 40%;
      pointer-events: none;
    }
    .image-loading {
      position: absolute;
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .ai-tag {
      width: 24px;
      height: 20px;
      background-color: var(--secondary-color);
      position: absolute;
      bottom: 0%;
      padding-left: 4px;
      border-top-right-radius: 8px;
      span {
        color: white;
      }
    }
  }
  .header {
    width: 100%;
    line-height: 2.5ex;
    height: 5ex;
    overflow: hidden;
    padding: 2px 4px;
    cursor: pointer;
  }
  .info {
    display: flex;
    justify-content: space-between;
    gap: 2px;
    width: 100%;
    .time {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 1px;
    }
    .add-btn {
      padding: 2px 0;
      min-width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      background-color: transparent;
      font-size: 0.87rem;
      &:hover {
        background-color: var(--primary-color);
        color: white;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .btns {
    padding: 4px 8px;
  }
  .favorite-btn,
  .delete-btn {
    background-color: var(--background-color);
    border-radius: 6px;
    height: 28px;
    position: absolute;
    top: 5%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-dark-color);
  }
  .delete-btn {
    top: 53%;
  }
  @media (max-width: 440px) {
    display: grid;
    grid-template-columns: 120px 160px;
    grid-template-rows: 60px 40px;
    width: 280px;
    box-shadow: 0px 0px 2px 0px var(--shadow);
    .img {
      grid-column: 1/2;
      grid-row: 1/3;
      border-radius: 0;
      max-height: 110px;
      img {
        border-bottom: none;
      }
    }
    .header {
      grid-column: 2/3;
      grid-row: 1/2;
      height: 7.5ex;
      text-decoration: underline;
    }
    .btns {
      grid-column: 2/3;
      grid-row: 2/3;
    }
    .favorite-btn,
    .delete-btn {
      right: 0% !important;
      right: 88% !important;
    }
  }
`;

export default RecipeCard;
