import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import LoadingButton from "../LoadingButton";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useSelector } from "react-redux";

function ProfileItem({
  name,
  options,
  inputType,
  submitHandler,
  style,
  loading,
}) {
  const preferences = useSelector((state) => state.user.preferences);
  const [values, setValues] = useState(
    preferences[name] ? preferences[name]?.values : []
  );
  const heightFRef = useRef();
  const heightIRef = useRef();
  const weightRef = useRef();
  const selectRef = useRef();
  const ageRef = useRef();

  const formSubmitHandler = (e, valueChange) => {
    e.preventDefault();
    if (inputType === "number") {
      console.log("values", heightFRef.current.value);
      submitHandler(
        {
          [name]: {
            values: [
              {
                heightF: heightFRef.current.value,
                heightI: heightIRef.current.value,
                weight: weightRef.current.value,
                gender: selectRef.current.value,
              },
            ],
          },
        },
        valueChange
      );
    } else {
      console.log("values", values, valueChange);
      submitHandler({ [name]: { values: values } }, valueChange);
    }
  };
  const onChangeHandler = (e) => {
    if (inputType === "radio") {
      console.log("radio", name, e.target.value);
      setValues([e.target.value]);
    } else {
      if (e.target.checked === false) {
        if (values.length > 0)
          setValues((current) =>
            current.filter((item) => item !== e.target.value)
          );
      } else {
        setValues((current) =>
          current?.length > 0 ? [...current, e.target.value] : [e.target.value]
        );
      }
    }
  };

  return (
    <StyledProfileItem style={style}>
      <div className="section">
        <h4>
          {name
            ?.split("_")
            .map((name) => name.charAt(0).toUpperCase() + name.slice(1) + " ")}
        </h4>
        <form onSubmit={(e) => formSubmitHandler(e)}>
          <div className="input-div">
            {inputType === "checkbox" || inputType === "radio" ? (
              options.map((option, index) => {
                return (
                  <label>
                    <input
                      key={index}
                      type={inputType}
                      className="checkbox"
                      name={inputType}
                      value={option}
                      defaultChecked={values?.includes(option)}
                      onChange={(e) => onChangeHandler(e)}
                      id={index}
                      required
                      placeholder={option}
                    />
                    {option}
                  </label>
                );
              })
            ) : (
              <>
                <input
                  type={inputType}
                  placeholder="Height(feet)"
                  defaultValue={
                    preferences[name] && preferences[name].values.heightF
                  }
                  ref={heightFRef}
                  // onChange={onChangeHandler}
                  required
                />
                <input
                  type={inputType}
                  placeholder="Height(inch)"
                  defaultValue={
                    preferences[name] && preferences[name].values.heightI
                  }
                  ref={heightIRef}
                  // onChange={onChangeHandler}
                  required
                />
                <input
                  type={inputType}
                  placeholder="Weight(lbs)"
                  defaultValue={
                    preferences[name] && preferences[name].values.weight
                  }
                  ref={weightRef}
                  // onChange={onChangeHandler}
                  required
                />
                <input
                  type={inputType}
                  placeholder="Age(years)"
                  defaultValue={
                    preferences[name] && preferences[name].values.age
                  }
                  ref={ageRef}
                  // onChange={onChangeHandler}
                  required
                />
                <select
                  placeholder="Gender"
                  defaultValue={
                    preferences[name] ? preferences[name].values.weight : "male"
                  }
                  key={10}
                  // onChange={onChangeHandler}
                  ref={selectRef}
                >
                  <optgroup>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </optgroup>
                </select>
              </>
            )}
          </div>
        </form>
      </div>
      <div className="btn-div">
        <button className="back-btn" onClick={(e) => formSubmitHandler(e, -1)}>
          Back
        </button>
        <LoadingButton
          loading={loading}
          onClick={(e) => formSubmitHandler(e, 1)}
          type="submit"
          title="Next"
        />
      </div>
    </StyledProfileItem>
  );
}
// }

const StyledProfileItem = styled.div`
  width: 82%;
  margin: auto;
  margin-bottom: 2rem;
  max-height: 50%;
  display: grid;
  /* grid-template-rows: 1fr 3fr; */
  align-items: center;
  gap: 10px;
  h4 {
    text-align: center;
    color: var(--secondary-color);
  }
  option:checked {
    background-color: var(--primary-color);
  }
  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    span {
      color: var(--text-light-color);
      font-size: 16px;
    }
  }
  .section {
    width: 100%;
    /* padding: 30px; */
    margin: 20px auto;
    border: 1px solid #f8f8f8;
    border-radius: 25px;
    background-color: #f8f8f8;
  }
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    .input-div {
      width: 100%;
      display: grid;
      /* grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(4, 1fr); */
      grid-template-columns: repeat(auto-fit, 160px);
      grid-column-gap: 10px;
      align-items: center;
      justify-content: center;
      height: 280px;
      overflow-y: scroll;
      label{
        align-self: center;
      }
      /* input,
      select {
        height: 42px;
      } */
      input,select {
        max-width: 140px;
      }
      .first-input {
        input {
          width: 50px;
        }
        .one {
          margin-right: 10px;
        }
      }

      .label-input {
        display: flex;
        align-items: center;
        gap: 10px;
        .checkbox {
          min-width: 18px;
          min-height: 18px;
        }
      }
    }
  }
  .btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    .back-btn {
      background-color: transparent;
      border: 1px solid var(--gray-color);
      color: var(--gray-color);
      margin-right: 20px;
      &:hover {
        border-color: var(--text-light-color);
        color: var(--text-light-color);
      }
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export default ProfileItem;
