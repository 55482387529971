import { createSlice } from "@reduxjs/toolkit";

const userRecipes = createSlice({
  name: "userRecipes",
  initialState: {
    manualRecipes: { loading: true, data: [] },
    webRecipes: { loading: true, data: [] },
    favoriteRecipes: { loading: true, data: [] },
    convertedRecipes: { loading: true, data: [] },
  },
  reducers: {
    setUserRecipes(state, action) {
      console.log("data", action.payload);
      state[`${action.payload.recipeType}Recipes`].data = action.payload.data;
      state[`${action.payload.recipeType}Recipes`].loading = false;
    },
    appendUserRecipes(state, action) {
      const recipe = state[`${action.payload.recipeType}Recipes`]
      recipe.data = recipe.data.length > 0 ? [
        ...recipe.data,
        action.payload.data,
      ] : [action.payload.data]
      recipe.loading = false;
    },
    deleteUserRecipe(state, action) {
      state[`${action.payload.recipeType}Recipes`].data = state[
        `${action.payload.recipeType}Recipes`
      ].data.filter((item) => item.id !== action.payload.data.id);
    },
    updateUserRecipe(state, action) {
      state[`${action.payload.recipeType}Recipes`].data = state[
        `${action.payload.recipeType}Recipes`
      ].data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload.data;
        } else return item;
      });
    },
  },
});

export const userRecipesActions = userRecipes.actions;
export const userRecipesReducer = userRecipes.reducer;
