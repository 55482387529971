import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import InstacartLogo from "../assets/Instacart_Carrot.png";
import { saveCartRecipes } from "../utils/recipesHandler";
import { sendRecipeToEmail } from "../utils/emails";
import { useSelector } from "react-redux";

export default function InstacartLink({ recipe, link, loading, title }) {
  const mediaQuery600 = useMediaQuery("(max-width: 600px)");
  const email = useSelector((state) => state.user.email);

  async function clickHandler() {
    await saveCartRecipes(recipe.id, "direct");
    await sendRecipeToEmail(email, recipe);
  }

  return (
    <StyledLink>
      {loading ? (
        <CircularProgress
          style={{
            color: "inherit",
            width: mediaQuery600 ? 18 : 20,
            height: mediaQuery600 ? 18 : 20,
          }}
        />
      ) : (
        <a target="blank" href={link} onClick={clickHandler}>
          <img src={InstacartLogo} alt="" />
          {title}
        </a>
      )}
    </StyledLink>
  );
}

const StyledLink = styled.div`
  min-width: 120px;
  padding: 2px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #108910;
  border-radius: 50px;
  color: white;
  &:hover {
    background-color: #0d760d;
  }
  a {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  img {
    width: 20px;
    height: auto;
  }
`;
